import { VirtualWallet, VirtualWalletManagerVersion, Token, IAsset } from '@dappos/v2-sdk';
import { Contracts } from '@dappos/v2-sdk';
import { defineStore } from 'pinia';
import { Raw, markRaw } from 'vue';
import { AddressZero } from '../constants';
import { NumberFixed, getAssetsByMulticall, getVirtualWalletAssets, isEqualAddress } from '../utils';
import { getProvider, getProviderAsync } from '../utils/provider';
import { ethers, providers } from 'ethers';
import { getConfig } from '../configs';
import { useNetworkStore, useTokenClassStore } from '../stores/checkout.store';
import { logger } from '../utils/logger';
import { Signer, TypedDataSigner } from '@ethersproject/abstract-signer';
import { BN } from '../utils';
import { DappOSApi } from '@dappos/sdk-core';

interface IAccountItem {
  chainId: number;
  virtualWallet: Raw<VirtualWallet>;
  assets: IAsset[];
}

type ICreatedAccounts = (
  | {
      virtualWallet: undefined;
      assets: IAsset[];
      chainId: number;
    }
  | IAccountItem
)[];

export interface AccountInfoConfig {
  ownerSigner: Signer & TypedDataSigner;
  provider: providers.Provider;
  owner?: string;
  chainId?: number;
  virtualwallets?: VirtualWallet[];
  connector?: {
    name: string;
    logo: string;
  };
  referralCode?: string;
  revoke?: boolean;
  version?: string;
}

interface IAccountState {
  chainId?: number;
  owner?: string;
  dappOSContracts?: Raw<Contracts>;
  assets: IAsset[];
  accounts: IAccountItem[];
  version: VirtualWalletManagerVersion;
  ownerSigner: Raw<AccountInfoConfig['ownerSigner']> | undefined;
  provider: Raw<AccountInfoConfig['provider']> | undefined;
  _connector?: AccountInfoConfig['connector'];
}

const hasVersion = (version: string): version is VirtualWalletManagerVersion => {
  return Object.values(VirtualWalletManagerVersion).includes(version as VirtualWalletManagerVersion);
};

const AccountStore = defineStore('AccountStore', {
  state: (): IAccountState => {
    return {
      owner: undefined,
      chainId: undefined,
      version: {} as VirtualWalletManagerVersion,
      assets: [],
      accounts: [],
      dappOSContracts: undefined,

      ownerSigner: undefined,
      provider: undefined,
      _connector: {
        name: 'MetaMask',
        logo: `${getConfig().dappOS_CDN}/dappLogo/metamask.png`,
      },
    };
  },
  getters: {
    eoaAccount: (state) => {
      return {
        virtualWallet: undefined,
        assets: state.assets,
      };
    },
    connector: (state) => {
      return (
        state?._connector ?? {
          name: 'MetaMask',
          logo: `${getConfig().dappOS_CDN}/dappLogo/metamask.png`,
        }
      );
    },
    accountList: (state): ICreatedAccounts => {
      const eoaAccount = {
        chainId: state.chainId!,
        virtualWallet: undefined,
        assets: state.assets,
      };
      return [eoaAccount, ...state.accounts];
    },
    createdAccount: (state): ICreatedAccounts => {
      const eoaAccount = {
        chainId: state.chainId!,
        virtualWallet: undefined,
        assets: state.assets,
      };
      return [eoaAccount, ...state.accounts.filter((e) => e.virtualWallet.walletCreated)];
    },
  },
  actions: {
    async connect(opt: AccountInfoConfig) {
      const account = opt.owner ?? (await opt.ownerSigner.getAddress());
      const version = opt.version && hasVersion(opt.version) ? opt.version : VirtualWalletManagerVersion.V2;

      this.$state.version = version;
      this.$state.chainId = opt.chainId ?? (await opt.provider.getNetwork()).chainId;
      this.$state.owner = account;
      this.$state.ownerSigner = opt.ownerSigner && markRaw(opt.ownerSigner);
      this.$state.provider = opt.provider && markRaw(opt.provider);

      const p = getProvider(this.$state.chainId);
      if (p) {
        this.ownerSigner; //?.connect(p);
      }

      const networkStore = useNetworkStore();
      const eoa_network = await networkStore.getEvmChainsParameter(this.$state.chainId, version);

      if (eoa_network?.contract) {
        const dappOSContracts = new Contracts({
          providerOrSigner: this.$state.provider,
          contracts: {
            paydb: eoa_network.contract.paydb,
            manager: eoa_network.contract.manager,
          },
        });
        this.dappOSContracts = markRaw(dappOSContracts);
        opt.ownerSigner && this.dappOSContracts.connect(opt.ownerSigner);
      }

      if (opt.connector)
        this.$state._connector = {
          name: opt.connector.name,
          logo: opt.connector.logo,
        };

      if (opt.virtualwallets && opt.virtualwallets?.length > 0) {
        await this.initAccounts(opt.virtualwallets);
        await this.updateAccountsAsset();
      } else {
        await this.init();
      }
      return version;
    },
    async init(virtualWallets?: VirtualWallet[]) {
      await this.initAccounts(virtualWallets);
      await this.updateAccountsAsset();
    },
    async initAccounts(virtualWallets?: VirtualWallet[]) {
      const { owner, chainId, version } = this.$state!;
      if (!owner || !chainId || !version) return;

      const networkStore = useNetworkStore();

      const tokenClassStore = useTokenClassStore();
      const tokenClassList = tokenClassStore.getTokenClassList();

      const getVirtualWalletAssets = async () => {
        const virtualWalletHistory = (await DappOSApi.getInstance().getVirtualWallets(owner)).infos ?? [];

        const networkList = markRaw(networkStore.evmChainsParameters.value)
          .map((e) => {
            return networkStore.getEvmChainsParameter(e.chainId, version);
          })
          .filter((e) => e?.contract);

        const vws = networkList.map(async (vw_network) => {
          if (vw_network?.contract?.manager) {
            const virtualWallet = virtualWalletHistory.find((e) => e.chainId === vw_network?.chainId && isEqualAddress(e.managerAddress, vw_network?.contract?.manager));
            const virtualWalletAddress = virtualWallet?.walletAddress ?? AddressZero;
            const provider = await getProviderAsync({ chainId: vw_network.chainId, rpcUrls: vw_network.rpcUrls });

            const vw = new VirtualWallet(owner, {
              chainId: vw_network.chainId,
              provider: provider,
              address: virtualWalletAddress,
              version: version,
              contracts: vw_network.contract,
            });
            if (!vw.walletCreated) {
              await vw.getAddress();
            }
            return vw;
          }
        }) as Promise<VirtualWallet>[];

        const vw = virtualWallets && virtualWallets.length > 0 ? virtualWallets : (await Promise.all(vws)).filter(Boolean).flat();

        const vwAccounts = await Promise.all(
          vw.map(async (virtualwallet) => {
            const assetsList = tokenClassList
              .filter((e) => e.toContainChainId(Number(virtualwallet.chainId)))
              .map((e) => {
                return {
                  tokenClass: e,
                  whiteList: e.whitelists.filter((e) => e.chainId === Number(virtualwallet.chainId)),
                };
              })
              .filter((e) => e.whiteList.length > 0);

            const assets: IAsset[] = assetsList
              .flatMap((e) => e.whiteList)
              .map((e) => {
                const balance = new BN('0').dividedBy(10 ** e.tokenDecimal);

                const price = tokenClassStore.getTokenClass(e.tokenAddress, e.chainId)?.price ?? '0';
                return {
                  holderAddress: virtualwallet.address,
                  address: e.tokenAddress,
                  name: e.tokenName,
                  symbol: e.tokenSymbol,
                  decimals: e.tokenDecimal,
                  balance: balance.toString(),
                  balanceUsd: balance.times(price).toString(),
                  tokenPrice: price,
                  token: new Token({
                    ...e,
                    price: price,
                    tokenDecimals: e.tokenDecimal,
                  }),
                };
              });

            return {
              chainId: virtualwallet.chainId,
              virtualWallet: markRaw(virtualwallet),
              assets: assets,
            };
          }),
        );
        this.accounts = vwAccounts;
      };

      const getEoaAssets = async () => {
        const assetsList = tokenClassList
          .filter((e) => e.toContainChainId(Number(chainId)))
          .map((e) => {
            return {
              tokenClass: e,
              whiteList: e.whitelists.filter((e) => e.chainId === Number(chainId)),
            };
          })
          .filter((e) => e.whiteList.length > 0);

        this.assets = assetsList
          .flatMap((e) => e.whiteList)
          .map((e) => {
            const balance = new BN('0').dividedBy(10 ** e.tokenDecimal);

            const price = tokenClassStore.getTokenClass(e.tokenAddress, e.chainId)?.price ?? '0';
            return {
              holderAddress: owner,
              address: e.tokenAddress,
              name: e.tokenName,
              symbol: e.tokenSymbol,
              decimals: e.tokenDecimal,
              balance: balance.toString(),
              balanceUsd: balance.times(price).toString(),
              tokenPrice: price,
              token: new Token({
                ...e,
                price: price,
                tokenDecimals: e.tokenDecimal,
              }),
            };
          })
          .sort((a, b) => {
            const isNativeToken = a.address === AddressZero;
            if (isNativeToken !== (b.address === AddressZero)) {
              return isNativeToken ? 1 : -1;
            }
            return 0;
          });
      };

      await Promise.all([getEoaAssets(), getVirtualWalletAssets()]);
    },

    /**
     * updateAccountsAsset
     * @description update accounts asset
     */
    async updateAccountsAsset() {
      const networkStore = useNetworkStore();

      await Promise.all(
        this.accountList.map(async (e) => {
          const addressList = e.assets.map((e) => e.address);
          if (e.virtualWallet) {
            if (!e.virtualWallet.walletCreated) {
              e.virtualWallet._getAddressPromise = undefined;
              await e.virtualWallet.getAddress({ cache: false });
            }
            if (e.virtualWallet.address !== ethers.constants.AddressZero) {
              const balances = await getVirtualWalletAssets(e.virtualWallet, addressList).catch((err) => {
                logger.debug(e, err);
                return addressList.map(() => ({
                  balance: '0.0',
                }));
              });

              e.assets.forEach((asset, i) => {
                const balanceItem = balances[i];
                const balance = new BN(balanceItem?.balance).dividedBy(10 ** asset.decimals);

                asset.balance = NumberFixed(balance, asset.decimals).toString();
                asset.balanceUsd = NumberFixed(balance.times(asset.tokenPrice), asset.decimals).toString();
              });
            }
          } else if (!e.virtualWallet && this.chainId && this.owner) {
            const eoa_network = networkStore.getEvmChainsParameter(this.chainId, this.version);
            if (eoa_network) {
              const balances = await getAssetsByMulticall({
                chainId: this.chainId,
                provider: await getProviderAsync({
                  chainId: this.chainId,
                  rpcUrls: eoa_network.rpcUrls,
                }),
                tokens: this.assets.map((e) => e.address),
                owner: this.owner,
              }).catch((e) => {
                console.error(e);
                return [];
              });
              e.assets.forEach((asset, i) => {
                const balanceItem = balances[i];
                const balance = new BN(balanceItem?.balance).dividedBy(10 ** asset.decimals);
                asset.balance = NumberFixed(balance, asset.decimals).toString();
                asset.balanceUsd = NumberFixed(balance.times(asset.tokenPrice), asset.decimals).toString();
              });
            }
          }
        }),
      ).catch((e) => {
        console.warn(e);
      });
    },

    getProvider() {
      if (!this.provider) throw new Error('provider is not set');
      return this.provider;
    },
    getSigner() {
      if (!this.ownerSigner) throw new Error('signer is not set');
      return this.ownerSigner;
    },
    async resetOwner(owner: string) {
      this.owner = owner;
      this.assets = [];
      this.accounts = [];
      await this.init();
    },
  },
});

export { AccountStore };
export type { IAccountState, ICreatedAccounts, IAccountItem };
