import { MetaData, useWalletLiteState } from '~/stores/wallet-lite.store';
import WalletLite from '../components/wallet-lite/wallet-lite.ce';
import { ConfigEnvironment, ConfigService, IVirtualWallet } from '@dappos/sdk-core';

export interface IConfigOption {
  env: ConfigEnvironment | string;
  metadata: IMetaData;
  rpcMaps?: Record<number, string[]>;
}
export interface IMetaData {
  name: string;
  icon?: string;
  url?: string;
  description?: string;
  env?: ConfigEnvironment | string;
}

export interface ItemData extends IVirtualWallet {
  blockExplorerUrl: string;
  iconUrl: string;
  chainName: string;
}

class DappOSWalletLiteInstance {
  private static instance: DappOSWalletLiteInstance;
  // readonly events = new Event<WalletLiteEvents>();

  private constructor() {
    this.registerApp();
  }

  private registerApp() {
    customElements.define('dappos-wallet-lite', WalletLite);
  }

  static getInstance() {
    if (!DappOSWalletLiteInstance.instance) {
      DappOSWalletLiteInstance.instance = new DappOSWalletLiteInstance();
    }
    return DappOSWalletLiteInstance.instance;
  }
}

interface WalletLiteEvents {
  disconnect: () => unknown | Promise<unknown>;
}

class DappOSWalletLite {
  readonly dappOSWalletLiteInstance = DappOSWalletLiteInstance.getInstance();

  constructor(metadata: MetaData, env?: string) {
    const walletLite = useWalletLiteState();
    walletLite.state.value.metadata = metadata;
    env && ConfigService.getInstance().setEnv(env);
  }

  public async connect(owner: string, supportDstChains: number[], chainsToCreateVw: number[]) {
    const walletLite = useWalletLiteState();

    const result = await walletLite.connect({
      owner,
      supportDstChains,
      chainsToCreateVw,
    });
    return result;
  }

  public async disconnect() {
    const walletLite = useWalletLiteState();
    await walletLite.disconnect();
  }
}

export { DappOSWalletLiteInstance, DappOSWalletLite };
export type { WalletLiteEvents, MetaData };
