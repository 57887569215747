import { computed, defineComponent, inject } from 'vue';
import TxBridgeFeeItem from './item';
import BigNumber from 'bignumber.js';
import { RuntimeProvideKey } from '../../../../stores/runtime.store';
import './index.less';
import { NumberFixed } from '../../../../utils';

export default defineComponent({
  name: 'NodeList',
  components: {
    TxBridgeFeeItem,
  },
  setup() {
    const runtimeInject = inject(RuntimeProvideKey)!;

    const bridgeFeeUsd = computed(() => {
      const bridgeFee = new BigNumber(runtimeInject.solution?.bridgeFeeUSD ?? 0).toNumber();
      return NumberFixed(bridgeFee, 4);
    });
    return () => (
      <>
        <div class='dappos-bridge-fee dappos-checkout-content'>
          <span class='dappos-text-md dappos-font-bold'>Best Node</span>

          <div class='dappos-bridge-table dappos-mt-8'>
            <div class='dappos-bridge-fee-item dappos-gas-table-head'>
              <div>Node Name</div>
              <div>Margin</div>
              <div class='dappos-hidden-md'>Supported Network</div>
              <div>Bridge Fee</div>
            </div>

            <TxBridgeFeeItem />
          </div>

          <div class='dappos-gas-fee-total'>
            <div> Total </div>
            <div>
              <div>${bridgeFeeUsd.value}</div>
              <div class='dappos-text-desc dappos-text-text-sm'></div>
            </div>
          </div>
        </div>
      </>
    );
  },
});
