import { ICallParam } from '@dappos/v2-sdk';
import { ethers } from 'ethers';

export * from './gas-used';
export * from './abis';

export const AddressZero = '0x0000000000000000000000000000000000000000';
export const DataHashZero = ethers.utils.keccak256('0x'); // 0xc5d2460186f7233c927e7db2dcc703c0e500b653ca82273b7bfad8045d85a470
export const DappOSWrapperElementId = 'dappos-wrapper';

export const NODE_CALL_DATA: ICallParam = {
  node: AddressZero,
  nodeCallData: '0x',
};

export const ChainWeight: {
  chainId: number;
  weight: number;
}[] = [
  { chainId: 250, weight: 800 },
  { chainId: 1, weight: 900 },
  { chainId: 53935, weight: 920 },
  { chainId: 1101, weight: 930 },
  { chainId: 5, weight: 950 },
  { chainId: 321, weight: 940 },
  { chainId: 169, weight: 951 },
  { chainId: 42161, weight: 960 },
  { chainId: 10, weight: 970 },
  { chainId: 43114, weight: 990 },
  { chainId: 137, weight: 995 },
  { chainId: 56, weight: 1000 },
];

export const getChainWeight = (chainId: number) => {
  const weight = ChainWeight.find((item) => item.chainId === chainId)?.weight;
  return weight || 0;
};
