import { markRaw } from 'vue';
import { AddressZero } from '../../constants';
import { AccountStore } from '../../stores';
import { useTokenClassStore } from '../../stores/checkout.store';
import { BN } from '../../utils';

interface ITokenWhiteListBalanceResult {
  whitelists: {
    price: string;
    balance: string;
    balanceUsd: string;
    id: number;
    tokenClassId: number;
    chainId: number;
    tokenName: string;
    tokenSymbol: string;
    tokenAddress: string;
    tokenDecimal: number;
  }[];
  id: number;
  price: string;
  icon: string;
  balance: string;
  balanceUsd: string;
  tokenClassName: string;
}
async function getTokenWhiteListBalance(owner: string, opt: { cache: boolean; useEoaAccount?: boolean; useNativeToken?: boolean } = { cache: true }): Promise<ITokenWhiteListBalanceResult[]> {
  const findEoaAssets = typeof opt?.useEoaAccount === 'boolean' ? opt.useEoaAccount : false;
  const useNativeToken = typeof opt?.useNativeToken === 'boolean' ? opt.useNativeToken : true;
  const cache = typeof opt?.cache === 'boolean' ? opt.cache : true;
  const accountStore = AccountStore();
  const tokenClassStore = useTokenClassStore();

  const result = await Promise.all([!cache && tokenClassStore.tokenWhiteList.value.length > 0 && tokenClassStore.updateState()]).then(() => {
    return tokenClassStore.tokenWhiteList.value.map((_t) => {
      const t = markRaw(_t);
      const price = new BN(t.price);
      const whiteLists = (t.whitelists ?? []).map((e) => {
        const accounts = findEoaAssets ? accountStore.accountList.filter((e) => e.virtualWallet?.walletCreated || !e.virtualWallet) : accountStore.createdAccount.filter((e) => e.virtualWallet);

        let balance = '0';
        let balanceUsd = '0';

        accounts.forEach((account) => {
          const asset = account?.assets
            .filter((e) => {
              if (!useNativeToken) {
                return e.address != AddressZero;
              }
              return true;
            })
            .find((t) => e.chainId === account.chainId && t.address.toLowerCase() === e.tokenAddress.toLowerCase());
          if (asset) {
            balance = new BN(asset.balance).plus(balance).toFixed();
            balanceUsd = new BN(asset.balanceUsd).plus(balanceUsd).toFixed();
          }
        });

        return {
          ...e,
          price: price.toFixed(),
          balance: balance,
          balanceUsd: balanceUsd,
        };
      });
      return {
        ...t,
        price: price.toFixed(),
        whitelists: whiteLists,
        balance: whiteLists.reduce((a, b) => a.plus(b.balance ?? 0), new BN(0)).toFixed(),
        balanceUsd: whiteLists.reduce((a, b) => a.plus(b.balanceUsd ?? 0), new BN(0)).toFixed(),
      };
    });
  });

  return result;
}
export { getTokenWhiteListBalance };
export type { ITokenWhiteListBalanceResult };
