import { defineComponent } from 'vue';
import TotalFee from './total-fee';
import ErrorFeedback from './error-feedback';
import { useRuntimeStore } from '../../../stores/runtime.store';
import AppMetaData from './app-metadata';
import { Button as DButton } from '../../../ui/components';
export default defineComponent({
  name: 'TxConfirm',
  components: {
    AppMetaData,
    TotalFee,
    ErrorFeedback,
    DButton,
  },
  setup() {
    const { confirmTransaction, awaitTransaction, modalCtrl, rejectDisabled, confirmDisabled, retryExecute } = useRuntimeStore();
    const { isLoading, error, isReady } = awaitTransaction;

    const { isLoading: executeLoading, execute: handConfirm } = confirmTransaction;

    const tryConfirm = async () => {
      retryExecute();
    };
    return () => (
      <>
        <div class='dappos-checkout-content'>
          <AppMetaData />
          {isLoading.value && (
            <div class='dappos-flex dappos-items-center dappos-justify-center dappos-my-16'>
              <Icon-eos-icons-loading />
            </div>
          )}

          {!isLoading.value && error.value && (
            <div class='dappos-my-16 dappos-text-center'>
              <p> Please try again, error: {error.value} </p>
              <DButton size='sm' type='outline' onClick={() => tryConfirm()}>
                <span>Retry</span>
              </DButton>
            </div>
          )}

          {isReady.value && (
            <>
              <div class='dappos-mt-8'>
                <TotalFee />
              </div>
              <ErrorFeedback />
            </>
          )}

          <div class='dappos-confirm-footer dappos-flex dappos-items-center dappos-justify-end dappos-gap-8'>
            <DButton size='lg' type='outline' disabled={rejectDisabled.value} onClick={() => modalCtrl.reject()}>
              <span>Reject</span>
            </DButton>
            <DButton size='lg' type='primary' loading={executeLoading.value} disabled={confirmDisabled.value} onClick={() => handConfirm()}>
              <span>Confirm</span>
            </DButton>
          </div>
        </div>
      </>
    );
  },
});
