import { VirtualWallet } from '@dappos/v2-sdk';
import { AddressZero } from '../../constants';
import { DappOSCheckoutInstance } from '../../core';
import { _executeIsolateOrderETH } from '../../export/interface/execute-isolate-order';

interface IsolateCreateWalletParam {
  text: string;
  gasLimit?: string;
  virtualWallet: VirtualWallet;
}

async function isolateCreateWallet(param: IsolateCreateWalletParam) {
  const { virtualWallet } = param;

  const result = await _executeIsolateOrderETH({
    app: DappOSCheckoutInstance.getInstance().metadata?.name ?? '',
    text: param.text,
    virtualWallet,
    expTime: Math.floor(new Date(new Date().getTime() + 24 * 60 * 60 * 1000).getTime() / 1000),
    service: AddressZero,
    executeOrderParams: [],
    receiver: AddressZero,
    data: '0x',
    isGateWay: false,
    gasLimit: param.gasLimit ?? '500000',
    signature: false,
  });

  return result.orderHash;
}

export { isolateCreateWallet };
export type { IsolateCreateWalletParam };
