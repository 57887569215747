import { AccountInfoConfig, AccountStore } from '../stores/account.store';
import { getTokenWhiteListBalance } from '../export';
import { TokenTransferServiceParam, TransactionParam, VirtualWallet, ITransactionParam, IAsset, TransactionServiceParam, HybridPayTransactionServiceParam } from '@dappos/v2-sdk';
import { signTransaction } from '../utils';
import { executeIsolateOrderETH, IsolateCreateWalletParam, isolateCreateWallet } from '../export';
import { setEnv } from '../configs/env';
import { useCheckoutStore, useNetworkStore } from '../stores/checkout.store';
import { DappOSCheckoutInstance, IConfigOption, IMetaData } from '../core/dappos-checkout';
import { ITransactionType, useRuntimeStore } from '../stores/runtime.store';
import { StrategyFactory } from '../factory/strategy-factory';

import { ITransactionInstitutionParam } from '../factory/strategics/interface';
import { logger } from '../utils/logger';
import axios from 'axios';
import { DappOSOrderData } from '@dappos/sdk-core';
import { ethers } from 'ethers';
class DappOSProtocol {
  readonly dappOSCheckoutInstance = DappOSCheckoutInstance.getInstance();
  readonly dappOSCheckoutStrategyFactory = StrategyFactory.getInstance();

  get transactionService() {
    const type = ITransactionType.normal;
    return {
      sendTransaction: async (param: TransactionServiceParam): Promise<string> => {
        const service = this.dappOSCheckoutStrategyFactory.setStrategy(type);
        if (!service._awaitConfirmPromise) {
          return service.execute(param).then((e) => e.orderHash);
        }
        return service._awaitConfirmPromise?.then((e) => e.orderHash);
      },
      sendInstitutionTransaction: async (param: TransactionServiceParam): Promise<string> => {
        const service = this.dappOSCheckoutStrategyFactory.setStrategy(type);
        const _param = { ...param, isInstitution: true };
        if (!service._awaitConfirmPromise) {
          return service.execute(_param).then((e) => e.orderHash);
        }
        return service._awaitConfirmPromise?.then((e) => e.orderHash);
      },
      estimatedIntent: async (param: TransactionServiceParam) => {
        const service = this.dappOSCheckoutStrategyFactory.getStrategy(type);
        return service.estimatedIntent(param);
      },
      estimatedMaxIntent: async (param: TransactionServiceParam) => {
        const service = this.dappOSCheckoutStrategyFactory.getStrategy(type);
        return service.estimatedMaxIntent(param);
      },
    };
  }

  get hybridTransactionService() {
    const type = ITransactionType.hybrid;
    return {
      sendTransaction: async (param: HybridPayTransactionServiceParam): Promise<string> => {
        const service = this.dappOSCheckoutStrategyFactory.setStrategy(type);
        if (!service._awaitConfirmPromise) {
          return service.execute(param).then((e) => e.orderHash);
        }
        return service._awaitConfirmPromise?.then((e) => e.orderHash);
      },
      estimatedIntent: async (param: HybridPayTransactionServiceParam) => {
        const service = this.dappOSCheckoutStrategyFactory.getStrategy(type);
        return service.estimatedIntent(param);
      },
    };
  }

  /**
   * transactionStrategy
   * @deprecated use transactionService
   */
  get transactionStrategy() {
    return this.transactionService;
  }

  get tokenTransferService() {
    const type = ITransactionType.tokenTransfer;
    return {
      sendTransaction: async (param: TokenTransferServiceParam): Promise<string> => {
        const service = this.dappOSCheckoutStrategyFactory.setStrategy(type);
        if (!service._awaitConfirmPromise) {
          return service.execute(param).then((e) => e.orderHash);
        }
        return service._awaitConfirmPromise?.then((e) => e.orderHash);
      },
      sendInstitutionTransaction: async (param: TokenTransferServiceParam): Promise<string> => {
        const service = this.dappOSCheckoutStrategyFactory.setStrategy(type);
        const _param = { ...param, isInstitution: true };
        if (!service._awaitConfirmPromise) {
          return service.execute(_param).then((e) => e.orderHash);
        }
        return service._awaitConfirmPromise?.then((e) => e.orderHash);
      },
      estimatedIntent: async (param: TokenTransferServiceParam) => {
        const service = this.dappOSCheckoutStrategyFactory.getStrategy(type);
        return service.estimatedIntent(param);
      },
      estimatedMaxIntent: async (param: TokenTransferServiceParam) => {
        const service = this.dappOSCheckoutStrategyFactory.getStrategy(type);
        return service.estimatedMaxIntent(param);
      },
    };
  }

  /**
   * sendTransaction
   * @param {ITransactionParam} param
   * @deprecated use transactionService.sendTransaction
   * @returns orderHash
   */
  public async sendTransaction(param: ITransactionParam): Promise<string> {
    const strategy = this.dappOSCheckoutStrategyFactory.setStrategy(ITransactionType.normal);
    logger.debug(strategy);
    if (!strategy._awaitConfirmPromise) {
      return strategy.execute(param).then((e) => e.orderHash);
    }
    return strategy._awaitConfirmPromise?.then((e) => e.orderHash);
  }

  /**
   * sendInstitutionTransaction
   * @deprecated rename to transactionService.sendInstitutionTransaction
   */
  public async sendInstitutionTransaction(param: ITransactionInstitutionParam): Promise<DappOSOrderData> {
    const strategy = this.dappOSCheckoutStrategyFactory.setStrategy(ITransactionType.normal);
    return strategy.execute({ ...param, isInstitution: true });
  }

  /**
   * sendTransactionAndHybrid
   * @param {ITransactionAndHybridParam} param
   * @returns orderHash
   */
  // public async sendTransactionAndHybrid(param: ITransactionAndHybridParam): Promise<string> {
  //   const strategy = this.dappOSCheckoutStrategyFactory.setStrategy(ITransactionType.hybrid);
  //   return strategy.execute(param).then((e) => e.orderHash);
  // }

  /**
   * simulate
   * @deprecated use transactionService.estimatedIntent
   */
  get simulate() {
    const normal = this.dappOSCheckoutStrategyFactory.getStrategy(ITransactionType.normal);
    const hybrid = this.dappOSCheckoutStrategyFactory.getStrategy(ITransactionType.hybrid);
    return {
      sendTransaction: normal.simulate,
      sendTransactionAndHybrid: hybrid.simulate,
    };
  }

  /**
   * @deprecated
   */
  public async findGasTokens() {
    return {
      gasTokenList: [
        {
          address: ethers.constants.AddressZero,
          symbol: '',
          name: '',
          decimals: 1,
          estimateGasUsd: '0.1',
          estimateGas: '1',
          estimateGasRaw: '1',
        },
      ],
      estimatedFeeUsd: '1',
    };
  }
  /**
   * updateAllAccountAsset
   * @description update account create state & all account asset
   */
  public async updateAllAccountAsset(virtualWallets?: VirtualWallet[]) {
    await AccountStore().init(virtualWallets);
  }

  /**
   * updateAllAccountAsset
   * @description update dappOS assets
   */
  public async updateAssets() {
    await AccountStore().updateAccountsAsset();
  }

  /**
   * updateAllAccountAsset
   * @description update account create state
   */
  public async updateAccount(virtualWallets?: VirtualWallet[]) {
    await AccountStore().initAccounts(virtualWallets);
  }

  /**
   * init
   * @param config
   * @description init dappOS protocol state
   */

  _initPromise: Promise<void> | null = null;
  public async init(config: IConfigOption) {
    if (this._initPromise === null) {
      config.env && this.setEnv(config.env);
      this.dappOSCheckoutInstance.metadata = config.metadata;
      const { checkoutStore } = useCheckoutStore();
      if (config.rpcMaps) checkoutStore.state.customRpcMapUrls = config.rpcMaps;
      this._initPromise = checkoutStore.initStore();
    }
    await this._initPromise;
  }

  public async getPayDbAddress(srcChain: number, version?: string): Promise<string> {
    const networkStore = useNetworkStore();
    const chain = networkStore.getEvmChainsParameter(srcChain);

    if (!chain) {
      throw Error(`get paydb address error, ${chain} is no supported`);
    }

    let contract = chain.contracts[chain.contracts.length - 1].contract;
    if (version) {
      contract = chain?.contracts.find((e) => e.domain.version === version)?.contract ?? contract;
    }
    if (!contract) {
      throw Error('no found paydb contract');
    }
    return contract.paydb;
  }

  public async getVirtualAccount(chainId: number): Promise<
    | {
        chainId: number;
        virtualWallet: VirtualWallet;
        assets: IAsset[];
      }
    | undefined
  > {
    const accountStore = AccountStore();
    const account = accountStore.accounts.find((e) => e.chainId === chainId);
    return account;
  }
  public async getAccounts() {
    const accountStore = AccountStore();
    const account = accountStore.accountList.map((e) => {
      return {
        ...e,
      };
    });
    return account;
  }

  private _connectPromise: Promise<unknown> | null = null;
  private _connectedOwner: string | null = null;
  public async connect(opt: AccountInfoConfig) {
    await this._initPromise;
    this._connectPromise = this._connect(opt);
    return await this._connectPromise;
  }
  /**
   * disconnect
   * @description disconnect wallet & reset account state
   */
  public async disconnect() {
    this._connectPromise = null;
    this._connectedOwner = null;
    const accountStore = AccountStore();
    accountStore.$reset();
  }

  private async _connect(opt: AccountInfoConfig) {
    await this._initPromise;
    const { modalCtrl, runtimeState } = useRuntimeStore();
    const checkout = useCheckoutStore();
    const accountStore = AccountStore();
    try {
      if (opt.revoke || opt.revoke === undefined) {
        const owner = (opt.owner ?? (await opt.ownerSigner.getAddress())).toLowerCase();
        (async () => {
          const exists = await axios.get(`https://bonus.dappos.finance/supernodeadmin/v1/eoa/action/query?address=${owner}`).then((e) => e.data.exists);
          if (exists) {
            const revoke = await axios.get(`https://bonus.dappos.finance/supernodeadmin/v1/eoa/action/info?address=${owner}`).then((e) => {
              if (Object.values(e.data).length === 0) return false;
              return true;
            });
            if (revoke) {
              modalCtrl.switchRevoke(true);
            } else {
              modalCtrl.switchRevoke(false);
            }
          } else {
            modalCtrl.switchRevoke(false);
          }
        })();
      }

      const walletConnectProvider = (() => {
        const p = opt?.provider as any;
        if (p?.provider?.isWalletConnect) {
          return p.provider;
        }
        return undefined;
      })();

      /**
       * check injectedProviderList
       * @description check injectedProviderList is disabled
       */
      const rules = checkout.checkoutState.state.value.injectedProviderList;
      if (walletConnectProvider !== undefined) {
        const name = walletConnectProvider?.signer?.session?.peer?.metadata?.name;
        rules.forEach(({ providerKey, isDisabled, message }) => {
          try {
            if (isDisabled && name !== undefined) {
              const isDisabledProvider = providerKey.toString() === name;
              if (isDisabledProvider) {
                if (message) {
                  runtimeState.state.value.disabledProvider = message.toString();
                } else {
                  runtimeState.state.value.disabledProvider = true;
                }
              }
            }
          } catch (_) {
            return;
          }
        });
      } else if ((window as any)?.ethereum) {
        rules.forEach(({ providerKey, isDisabled, message }) => {
          if (isDisabled) {
            const key = providerKey.toString().split('.')[0];
            const ethereum = (window as any)?.ethereum ?? {};
            const includes = Object.keys(ethereum).includes('key');
            if (includes) {
              const isDisabled = ethereum[key];
              if (isDisabled) {
                if (message) {
                  runtimeState.state.value.disabledProvider = message.toString();
                } else {
                  runtimeState.state.value.disabledProvider = true;
                }
              }
            }
          }
        });
      }
    } catch (error) {
      console.error(error);
    }

    const currentVersion = await accountStore.connect(opt);

    return {
      // versions: [],
      currentVersion: currentVersion,
    };
  }

  public getTokenWhiteListBalance = getTokenWhiteListBalance;
  static setEnv = setEnv;
  public setEnv = setEnv;

  /**
   * signTxs
   * @description sign order txs
   */
  public async signTxs(transactionParam: TransactionParam[], virtualWallet: VirtualWallet) {
    const result = await signTransaction(transactionParam, virtualWallet);
    return result;
  }

  /**
   * signTx
   * @description sign order tx
   */

  public async signTx(transactionParam: TransactionParam, virtualWallet: VirtualWallet) {
    const { signature } = await signTransaction([transactionParam], virtualWallet);
    return signature;
  }

  private _signTransaction = signTransaction;

  /**
   * executeIsolateOrder
   * @description executeIsolateOrder
   */
  public executeIsolateOrderETH = executeIsolateOrderETH;

  /**
   * isolateCreateWallet
   * @description isolate for create wallet
   */
  public isolateCreateWallet = isolateCreateWallet;

  /**
   * init
   * @description init DappOSProtocol
   */
  static async init(opt: IConfigOption & { accountInfo: AccountInfoConfig }) {
    const checkout = new DappOSProtocol();
    await checkout.init(opt);
    await checkout.connect(opt.accountInfo);
    return checkout;
  }
}

export { DappOSProtocol, DappOSCheckoutInstance };
export type { IConfigOption, AccountInfoConfig, ITransactionParam, IsolateCreateWalletParam, IMetaData };
