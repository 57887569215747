import { ethers } from 'ethers';
import { AddressZero, vaultInfo_constants } from '../constants';
import { BaseContract } from '../contracts/base-contract';

export class Vault_Info extends BaseContract {
  async getVaults(nodeId: string, chainIds: number[]) {
    const result = await this.read('getVaults', [nodeId, chainIds]);
    return chainIds
      .map((chainId, index) => ({
        chainId,
        vaultAddress: result[index].toLowerCase() as string,
      }))
      .filter((item) => item.vaultAddress !== AddressZero);
  }

  static init(address: string, provider: ethers.providers.Provider) {
    return new Vault_Info(vaultInfo_constants, address, provider);
  }
}
