import { computed, defineComponent, inject } from 'vue';
import { getConfig } from '../../../../configs';
import { useNetworkStore } from '../../../../stores/checkout.store';
import { RuntimeProvideKey } from '../../../../stores/runtime.store';
import { Link as DLink } from '../../../../ui/components';
import { BN, NumberFixed, useToggle } from '../../../../utils';
import NodeCombinAccountsTable from './node-combin-accounts';

export default defineComponent({
  name: 'NodeListItem',
  components: {
    NodeCombinAccountsTable,
    DLink,
  },
  setup() {
    const networkStore = useNetworkStore();

    const runtimeInject = inject(RuntimeProvideKey)!;

    const bridgeFeeUsd = computed(() => {
      return NumberFixed(runtimeInject.solution.bridgeFeeUSD, 4);
    });

    const nodeSupportedNetwork = computed(() => {
      return runtimeInject.solution.serverNode?.nodeVaultInfos.map((n) => networkStore.getEvmChainsParameter(n.chainId)).filter(Boolean) ?? [];
    });

    const { isOpen, toggle } = useToggle(true);

    const stakeUsd = computed(() => {
      return new BN(runtimeInject.solution.serverNode?.availableAmount ?? 0).toFixed(4);
    });

    const nodeLink = computed(() => {
      if (!runtimeInject.solution.serverNode) return '';
      const config = getConfig();
      return `${config.dappOS_Wallet}/node?node_id=${runtimeInject.solution.serverNode?.id}`;
    });

    const isVerify = computed(() => {
      return true; //!!runtimeInject.transactionRaw.orderState.verify;
    });

    const nodeName = computed(() => {
      return runtimeInject.solution.serverNode?.name;
    });

    const networkList = computed(() => {
      return nodeSupportedNetwork.value.filter((_, i) => i < 5);
    });
    return () => (
      <>
        <div class='dappos-gas-fee-item'>
          <div>
            <DLink href={nodeLink.value} disabled={!isVerify.value}>
              {nodeName.value}
            </DLink>
          </div>
          <div>${stakeUsd.value}</div>
          <div class='dappos-hidden-md'>
            <div class='dappos-flex dappos-items-center dappos-gap-4'>
              {networkList.value.map((item) => (
                <img src={item?.iconUrl} alt={item?.chainName} class='dappos-chain-icon' />
              ))}
            </div>
          </div>
          <div class='dappos-gas-fee-bridge-col'>
            <div>${bridgeFeeUsd.value}</div>
            <icon-material-symbols-keyboard-arrow-down-rounded
              onClick={() => toggle()}
              class={{
                'dappos-cursor-pointer': true,
                'dappos-hidden': isOpen.value,
              }}
            />
            <icon-material-symbols-keyboard-arrow-up
              onClick={() => toggle()}
              class={{
                'dappos-cursor-pointer': true,
                'dappos-hidden': !isOpen.value,
              }}
            />
          </div>
        </div>
        <div
          class={{
            'dappos-hidden': !isOpen.value,
          }}
        >
          <NodeCombinAccountsTable />
        </div>
      </>
    );
  },
});
