import { ethers } from 'ethers';
import { Strategy } from './strategics/strategy';
import TokenTransferStrategy from './strategics/token-transfer-service';
import TransactionStrategy from './strategics/transaction-service';
import HybridTransactionStrategy from './strategics/hybrid-transaction-service';
import { ITransactionType } from '../stores/runtime.store';

export class StrategyFactory {
  private strategies: Map<ITransactionType, Strategy> = new Map();
  private static instance: StrategyFactory;

  constructor() {
    this.strategies.set(ITransactionType.normal, new TransactionStrategy());
    this.strategies.set(ITransactionType.tokenTransfer, new TokenTransferStrategy());
    this.strategies.set(ITransactionType.hybrid, new HybridTransactionStrategy());
  }

  private currentStrategy: Strategy | null = null;

  /**
   * setStrategy
   * @param {ITransactionType.normal} transactionType
   * @return {*}  {SendTransactionStrategy}
   * @memberof StrategyFactory
   */
  public setStrategy(transactionType: ITransactionType.normal): TransactionStrategy;
  public setStrategy(transactionType: ITransactionType.tokenTransfer): TokenTransferStrategy;
  public setStrategy(transactionType: ITransactionType.hybrid): HybridTransactionStrategy;
  public setStrategy(transactionType: ITransactionType) {
    switch (transactionType) {
      case ITransactionType.normal:
        this.currentStrategy = new TransactionStrategy();
        break;

      case ITransactionType.tokenTransfer:
        this.currentStrategy = new TokenTransferStrategy();
        break;

      case ITransactionType.hybrid:
        this.currentStrategy = new HybridTransactionStrategy();
        break;

      default:
        throw Error(ethers.errors.NOT_IMPLEMENTED);
    }
    return this.currentStrategy;
  }

  /**
   * getStrategy
   * @param {ITransactionType.normal} transaction
   * @return {*}  {SendTransactionStrategy}
   * @memberof StrategyFactory
   */
  public getStrategy(transaction: ITransactionType.normal): TransactionStrategy;
  public getStrategy(transaction: ITransactionType.tokenTransfer): TokenTransferStrategy;
  public getStrategy(transaction: ITransactionType.hybrid): HybridTransactionStrategy;
  public getStrategy(): Strategy;
  public getStrategy(transactionType?: ITransactionType): Strategy {
    if (transactionType !== undefined) {
      if (this.strategies.has(transactionType)) {
        return this.strategies.get(transactionType) as Strategy;
      }

      throw Error(ethers.errors.NOT_IMPLEMENTED);
    }
    const strategy = this.currentStrategy;
    if (!strategy) {
      throw Error(ethers.errors.NOT_IMPLEMENTED);
    }
    return strategy;
  }

  static getInstance() {
    if (!StrategyFactory.instance) {
      StrategyFactory.instance = new StrategyFactory();
    }
    return StrategyFactory.instance;
  }
}
