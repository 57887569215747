import { defineComponent, computed } from 'vue';
import { DappOSCheckoutInstance } from '../../../../core/dappos-checkout';
import './index.less';

export default defineComponent({
  name: 'AppMetaData',
  setup() {
    const metadata = computed(() => {
      const instance = DappOSCheckoutInstance.getInstance().metadata;
      return {
        name: instance?.name ?? 'Unknown',
        description: instance?.description ?? '',
        icon: instance?.icon ?? '',
        url: instance?.url ?? window.location.origin,
      };
    });
    return () => (
      <>
        <div class="dappos-app">
          <div class="dappos-app-logo">{metadata.value?.icon && <img src={metadata.value?.icon} alt="logo" />}</div>
          <div class="dappos-app-content">
            <div class="dappos-app-title">
              {metadata.value?.name}
              {metadata.value?.description && <span class="dappos-text-desc">({metadata.value?.description})</span>}
            </div>
            <div class="dappos-app-desc">{metadata.value?.url}</div>
          </div>
        </div>
      </>
    );
  },
});
