import BigNumber from 'bignumber.js';
import { computed, defineComponent, inject } from 'vue';
import Popper from 'vue3-popper';
import { CheckoutRouterViewEnum, RuntimeProvideKey, useRuntimeStore } from '../../../stores/runtime.store';
import { NumberFixed } from '../../../utils';

export default defineComponent({
  name: 'TxFeeTotal',
  components: {
    Popper,
  },
  setup() {
    const { routeCtrl } = useRuntimeStore();
    const runtimeInject = inject(RuntimeProvideKey)!;

    const routerPush = (name: CheckoutRouterViewEnum) => {
      routeCtrl.push(name);
    };

    const estimateGas = computed(() => {
      try {
        let estimatedFee = '0';
        if (!runtimeInject.solution.destinationTx?.gasToken?.isolate) {
          estimatedFee = runtimeInject.solution.destinationTx?.gasToken?.estimatedGasUsedFeeUSD ?? runtimeInject.solution.destinationTx?.estimatedFeeUSD;
        }
        const estimatedFeeUsd = runtimeInject.solution.sourceTxs
          .reduce((pre, next) => {
            const nextFee = new BigNumber(next.estimatedFeeUSD);
            return pre.plus(nextFee.isNaN() ? 0 : nextFee);
          }, new BigNumber(0))
          .plus(estimatedFee);
        return NumberFixed(estimatedFeeUsd, 4);
      } catch (_) {
        return NumberFixed(runtimeInject.solution.estimatedFeeUSD, 4);
      }
    });

    const estimateGasMax = computed(() => {
      // const maxFee = (runtimeInject.transactionRaw.estimatedFeeUSD ?? []).reduce((a, b) => {
      //   const feeInfo = b.feeInfo as IFeeInfoAbstract | undefined;
      //   const gasTokenClass = feeInfo?.gasToken ? tokenClass.getTokenClass(feeInfo.gasToken.tokenAddress, feeInfo.gasToken.chainId) : undefined;
      //   const gasFee_USD = feeInfo ? new BigNumber(feeInfo.gasTokenAmount || 0).times(gasTokenClass != undefined ? gasTokenClass.price : 1) : new BigNumber(0);
      //   return a.plus(gasFee_USD.isFinite() ? gasFee_USD : 0);
      // }, new BigNumber(0));
      return NumberFixed(BigNumber.maximum(runtimeInject.solution.estimatedFeeUSD), 4);
    });

    const bridgeFees = computed(() => {
      return NumberFixed(runtimeInject.solution.bridgeFeeUSD, 4);
    });

    const totalFee = computed(() => {
      const totalFee = new BigNumber(runtimeInject.solution.estimatedFeeUSD).plus(runtimeInject.solution.bridgeFeeUSD);
      return NumberFixed(totalFee, 4);
    });

    const bridgeFeeLen = computed(() => {
      if (runtimeInject.solution.orderType.isolatePayment) {
        return runtimeInject.solution.sourceTxs.filter((e) => e.virtualWallet).flatMap((e) => e.tx.createPays).length;
      }
      return runtimeInject.solution.sourceTxs.flatMap((e) => e.tx.createPays).length;
    });

    const bridgeFeeHide = computed(() => {
      if (runtimeInject.solution.orderType.isolateExecute) {
        return true;
      }
      if (runtimeInject.solution.orderType.isolatePayment) {
        return runtimeInject.solution.sourceTxs.filter((e) => e.virtualWallet).flatMap((e) => e.tx.createPays).length === 0;
      }
      return runtimeInject.solution.sourceTxs.flatMap((e) => e.tx.createPays).length === 0;
    });

    return () => (
      <>
        <div class='dappos-payment-total dappos-rounded-md dappos-px-2'>
          <div class='dappos-flex dappos-items-center dappos-justify-between dappos-payment-total-item dappos-text-md'>
            <div class='dappos-payment-label'>
              <span>Estimated gas Fees</span>
              <Popper hover class={'dappos-popper'}>
                {{
                  default: () => <icon-mdi-alert-circle-outline class='dappos-payment-total-icon' />,
                  content: () => <span> The gas fee is paid to the node that passes it on to the cryptocurrency miner processing the transaction. </span>,
                }}
              </Popper>
            </div>
            <div class='dappos-text-right'>
              <div class='dappos-text-md'>
                <span>${estimateGas.value}</span>
                <span> </span>
              </div>

              <div class='dappos-text-xs dappos-mt-2'>
                <span class='dappos-text-desc dappos-hidden-md'>Max Fee: ${estimateGasMax.value}</span>
                <span class='dappos-text-skin dappos-cursor-pointer' onClick={() => routerPush(CheckoutRouterViewEnum.FeeView)}>
                  {' '}
                  Details{' '}
                </span>
              </div>
            </div>
          </div>

          {!bridgeFeeHide.value && (
            <div class='dappos-flex dappos-items-center dappos-justify-between dappos-payment-total-item dappos-text-md'>
              <div class='dappos-payment-label'>
                <span>Bridge Fees</span>
                <Popper hover class={'dappos-popper'}>
                  {{
                    default: () => <icon-mdi-alert-circle-outline class='dappos-payment-total-icon' />,
                    content: () => <span> Each bridging operation incurs a bridge fee that is paid to the node in the dappOS network executing the transaction. dappOS does not profit from it. </span>,
                  }}
                </Popper>
              </div>
              <div class='dappos-text-right'>
                <div class='dappos-text-md'>
                  <span>${bridgeFees.value}</span>
                  <span> </span>
                </div>

                <div class='dappos-text-xs'>
                  <span class='dappos-text-desc'>Total {bridgeFeeLen.value} fees</span>
                  <span class='dappos-text-skin dappos-cursor-pointer' onClick={() => routerPush(CheckoutRouterViewEnum.BridgeFeeView)}>
                    {' '}
                    Details{' '}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div class='dappos-flex dappos-items-center dappos-justify-between dappos-payment-total-item dappos-border-t dappos-text-md'>
            <div class='dappos-payment-label'> Total {bridgeFeeLen.value > 0 && <span class='dappos-text-desc dappos-text-xs'>(Gas fee + Bridge fee)</span>} </div>
            <div>${totalFee.value}</div>
          </div>
        </div>
      </>
    );
  },
});
