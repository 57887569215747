import { PropType, computed, defineComponent, toRefs } from 'vue';
import { getConfig } from '../../../../../configs';
import { useNetworkStore } from '../../../../../stores/checkout.store';
import { NumberFixed, formatDigit } from '../../../../../utils';
import { AccountStore } from '../../../../../stores';
import { SourceTx } from '@dappos/v2-sdk';

export default defineComponent({
  name: 'CombinAccount',
  props: {
    combinAccount: {
      type: Object as PropType<SourceTx>,
      required: true,
    },
    hiddenDappos: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const { combinAccount, hiddenDappos } = toRefs(props);
    const isEoa = computed(() => {
      return !props.combinAccount.virtualWallet;
    });
    const networkStore = useNetworkStore();

    const pays = computed(() => {
      return combinAccount.value.tx.createPays.map((e) => {
        return {
          tokenIn: e.tokenIn,
          tokenInNetwork: combinAccount.value.network.evmChainParameter,
          tokenOut: e.tokenOut,
          tokenOutNetwork: networkStore.getEvmChainsParameter(e.tokenOut.chainId),
          amountIn: NumberFixed(e.amountIn, formatDigit(e.tokenIn.price!)),
          amountOut: NumberFixed(e.amountOut, formatDigit(e.tokenOut.price!)),
          bridgeFee: NumberFixed(e.bridgeFee, 6),
          bridgeFeeUsd: NumberFixed(e.bridgeFeeUSD, 4),
        };
      });
    });

    const logoUrl = computed(() => {
      return getConfig().dappOS_CDN + '/dappLogo/dappos.png';
    });

    const connector = computed(() => {
      const accountStore = AccountStore();
      return accountStore.connector;
    });
    return () => (
      <tr>
        <td class='dappos-hidden-md' style={hiddenDappos.value ? 'border-top: none' : ''}>
          <div class='dappos-account-connector'>
            {isEoa.value && (
              <div class='dappos-flex dappos-items-center dappos-gap-4'>
                <img src={connector.value.logo} alt={connector.value.name} />
                <span>{connector.value.name}</span>
              </div>
            )}

            {!isEoa.value && !hiddenDappos.value && (
              <div class='dappos-flex dappos-items-center dappos-gap-4'>
                <img src={logoUrl.value} alt='dappOS' />
                <span>dappOS</span>
              </div>
            )}
          </div>
        </td>

        {/* <td style={hiddenDappos.value ? 'border-top: none' : ''}>
          {pays.value.map((item) => (
            <div class="dappos-account-pay-item">{item.tokenIn.symbol}</div>
          ))}
        </td> */}

        <td class='pl-16-mobile' style={hiddenDappos.value ? 'border-top: none' : ''}>
          {pays.value.map((item) => (
            <div class='dappos-account-pay-item'>
              <div>
                {item.amountIn} {item.tokenIn.symbol}
              </div>
              <div class='dappos-account-pay-chain-input'>
                <div style={`background-color:${item.tokenInNetwork?.themeColor}`}></div>
                <span style={`color:${item.tokenInNetwork?.themeColor}`}>{item.tokenInNetwork?.chainName}</span>
              </div>
            </div>
          ))}
        </td>

        <td style={hiddenDappos.value ? 'border-top: none' : ''}>
          {pays.value.map((item) => (
            <div class='dappos-account-pay-item'>
              <div>
                {item.amountOut} {item.tokenOut.symbol}
              </div>
              <div class='dappos-account-pay-chain-output'>
                <div style={`background-color:${item.tokenOutNetwork?.themeColor}`}></div>
                <span style={`color:${item.tokenOutNetwork?.themeColor}`}>{item.tokenOutNetwork?.chainName}</span>
              </div>
            </div>
          ))}
        </td>

        <td style={hiddenDappos.value ? 'border-top: none' : ''}>
          {pays.value.map((item) => (
            <div class='dappos-account-pay-item'>
              <div> {item.bridgeFee} </div>
              <div> ${item.bridgeFeeUsd} </div>
            </div>
          ))}
        </td>
      </tr>
    );
  },
});
