import { ChainConfig, CompressedInfo, DappOSApi, DappOSServerNode, DappOSTokenClass, DisabledProvider } from '@dappos/sdk-core';
import { getEvmChainParameter, INetworkState } from '@dappos/v2-sdk';
import { useIntervalFn } from '@vueuse/core';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { setMulticallAddress } from 'ethers-multicall';
import { defineStore, storeToRefs } from 'pinia';
import { computed, markRaw, reactive } from 'vue';
import { IServerNode, TokenClass } from '@dappos/v2-sdk';
import { isEqualAddress } from '../utils';
import { setProvider } from '../utils/provider';
import { AccountStore } from './account.store';

const contractsFilter = <T = Record<string, string>>(e?: T) => Object.fromEntries(Object.entries(e ?? {}).filter(([, v]) => v && ethers.utils.isAddress(v as string))) as T;

const CheckoutStore = defineStore('CheckoutStore', () => {
  const state = reactive({
    tokenWhiteList: [] as DappOSTokenClass.TokenClass[],
    networkListState: [] as INetworkState[],
    customRpcMapUrls: {} as Record<number, string[]>,
    nodeList: [] as DappOSServerNode.ServerNode[],
    compressedInfo: [] as CompressedInfo[],
    injectedProviderList: [] as DisabledProvider[],
  });

  async function initTokenClassList() {
    const tokenClassList = await DappOSApi.getInstance().getTokenWhitelists();
    state.tokenWhiteList = tokenClassList;
  }

  async function initConfigForCDN() {
    const cdn = DappOSApi.getInstance();
    await Promise.all([
      cdn.getCompressedInfo().then((e) => {
        state.compressedInfo = e;
      }),
      cdn.getInjectedProviderName().then((e) => {
        state.injectedProviderList = e;
      }),
    ]);
  }

  async function updateNodeList() {
    const nodeInfos = await DappOSApi.getInstance().getNodeInfo();
    state.nodeList = nodeInfos;
  }

  async function updateTokenClassPrice() {
    const tokenWhiteList = await DappOSApi.getInstance().getTokenClassPrices();
    state.tokenWhiteList.forEach((e) => {
      const tokenClass = tokenWhiteList.find((p) => p.id === e.id);
      if (tokenClass) {
        e.price = tokenClass.price;
        e.priceLastUpdateTime = tokenClass.priceLastUpdateTime;
      }
    });

    const accountStore = AccountStore();
    accountStore.accountList.forEach((account) => {
      account.assets.forEach((asset) => {
        const result = state.tokenWhiteList.find((e) => e.whitelists.find((e) => e.tokenAddress.toLowerCase() === asset.token.address?.toLowerCase() && e.chainId === asset.token.chainId));
        const price = result?.price ?? '0';
        asset.token.tokenAbstract.price = price;
      });
    });
  }
  const updateNetworkListState = async () => {
    const result = await Promise.all([
      DappOSApi.getInstance().getChainConfig(),
      DappOSApi.getInstance()
        .getChainConfigByCDN_V2_1()
        .catch(() => []),
    ]).then(([contractConfig, chainsConfig]) => {
      const configs = contractConfig.map((e) => {
        // merge config
        const baseConfig = chainsConfig.find((chain) => chain.chainId === e.chainId)!;
        const chainId = e.chainId;

        const evmChainParameter = getEvmChainParameter(chainId);
        let rpcUrls = e.rpcUrls ?? evmChainParameter?.rpcUrls ?? [];
        if (state.customRpcMapUrls[chainId] && state.customRpcMapUrls[chainId].length > 0) {
          rpcUrls = state.customRpcMapUrls[chainId];
        }

        const staticContracts = contractsFilter(evmChainParameter?.contract);
        const cdnContracts = contractsFilter(baseConfig?.contracts[baseConfig?.contracts.length - 1].contract);
        const apiContracts = contractsFilter(e?.contract);

        const contracts: ChainConfig['contract'] =
          !baseConfig && !e?.contract
            ? staticContracts
            : {
                ...staticContracts,
                ...cdnContracts,
                ...apiContracts,
              };

        const nativeToken = state.tokenWhiteList
          .flatMap((e) => {
            return (e.whitelists ?? []).map((w) => {
              return {
                ...w,
                tokenPrice: e.price,
              };
            });
          })
          .find((t) => t.chainId === e.chainId && isEqualAddress(t.tokenAddress, ethers.constants.AddressZero));

        const gasPrice = e.gasPrice ?? '0';
        const nativeTokenPrice = nativeToken?.tokenPrice || new BigNumber(0).toString();
        const gasTokenPrice = new BigNumber(nativeTokenPrice).times(gasPrice).div(1e10).toString();

        return {
          chainName: e.chainName,
          evmChainParameter: {
            ...baseConfig,
            chainId: chainId,
            rpcUrls: rpcUrls,
            contract: contracts,
            contracts: baseConfig?.contracts,
          },
          gwei: gasPrice,
          gasTokenPrice: gasTokenPrice,
          nativeTokenPrice: nativeTokenPrice,
        };
      });
      return configs.filter((e) => e.evmChainParameter.rpcUrls.length > 0 && e.evmChainParameter.contract.manager);
    });
    state.networkListState = result;
    result.forEach((e) => {
      if (e.evmChainParameter.contract && ethers.utils.isAddress(e.evmChainParameter.contract.multicall)) {
        setMulticallAddress(e.evmChainParameter.chainId, e.evmChainParameter.contract.multicall);
      }
      if (!e.evmChainParameter.rpcUrls?.[0]) return;
      setProvider({
        chainId: e.evmChainParameter.chainId,
        rpcUrl: e.evmChainParameter.rpcUrls[0],
        rpcUrls: e.evmChainParameter.rpcUrls,
      });
    });
  };

  async function initStore() {
    await Promise.all([initTokenClassList(), updateNodeList(), initConfigForCDN()]);
    await updateNetworkListState();
  }

  function updateState() {
    return Promise.all([updateTokenClassPrice(), updateNodeList(), updateNetworkListState]);
  }

  function updateTokenWhiteList() {
    return initTokenClassList();
  }

  const intervalUpdate = [
    useIntervalFn(
      async () => {
        await Promise.all([updateTokenClassPrice(), updateNodeList(), initConfigForCDN()]);
      },
      1000 * 40,
      { immediate: true },
    ),
    useIntervalFn(
      async () => {
        await updateNetworkListState();
      },
      1000 * 25,
      { immediate: true },
    ),
  ];

  const resumeUpdate = () => {
    intervalUpdate.forEach((e) => e.resume());
  };

  const pauseUpdate = () => {
    intervalUpdate.forEach((e) => e.pause());
  };

  return {
    state,
    updateNetworkListState,
    initStore,
    updateState,
    resumeUpdate,
    pauseUpdate,
    updateNodeList,
    updateTokenWhiteList,
  };
});

export const useCheckoutStore = () => {
  const store = CheckoutStore();
  const state = storeToRefs(store);

  return {
    checkoutState: state,
    checkoutStore: store,
  };
};

export const useTokenClassStore = () => {
  const store = CheckoutStore();
  const { state } = storeToRefs(store);

  const tokenWhiteList = computed(() => {
    return state.value.tokenWhiteList;
  });

  const getTokenClassList = () => {
    const result = tokenWhiteList.value.map((e) => new TokenClass(e));
    return markRaw(result);
  };

  const tokenList = computed(() => {
    return tokenWhiteList.value
      .map((e) => {
        return (e.whitelists ?? []).map((token) => {
          return {
            ...token,
            price: e.price,
            tokenClassName: e.tokenClassName,
          };
        });
      })
      .flat();
  });

  const getTokenClass = (address: string, chainId: number) => {
    const result = getTokenClassList().find((e) => e.whitelists.find((e) => e.tokenAddress.toLowerCase() === address?.toLowerCase() && e.chainId === chainId));
    return result ? markRaw(result) : undefined;
  };

  const getPriceByAddress = (address: string, chainId: number) => {
    return getTokenClass(address, chainId)?.price ?? '0';
  };

  const getTokenBySymbol = (symbol?: string) => {
    const result = getTokenClassList().find((p) => p.whitelists.find((e) => e.tokenSymbol === symbol));
    return result ? markRaw(result) : undefined;
  };

  const updateState = () => {
    return store.updateTokenWhiteList();
  };
  return {
    tokenList,
    tokenWhiteList,
    getTokenClassList,
    getTokenClass,
    getPriceByAddress,
    getTokenBySymbol,
    updateState,
  };
};

export const useNetworkStore = () => {
  const store = CheckoutStore();
  const { state } = storeToRefs(store);

  const getEvmChainsParameter = (chainId: number, version?: string) => {
    const evmChainParameter = state.value.networkListState.map((e) => e.evmChainParameter).find((e) => e.chainId === chainId);
    return (
      evmChainParameter &&
      markRaw({
        ...evmChainParameter,
        contract: evmChainParameter?.contracts?.find((e) => e.domain.version === version)?.contract,
      })
    );
  };
  const evmChainsParameters = computed(() => {
    return state.value.networkListState.map((e) => e.evmChainParameter);
  });

  const getNetworkByChainId = async (chainId: number) => {
    const result = state.value.networkListState.find((e) => e.evmChainParameter.chainId === chainId)!;
    return markRaw(result);
  };

  return {
    evmChainsParameters,
    getEvmChainsParameter,
    getNetworkByChainId,
  };
};

export const useNodeInfoStore = () => {
  const store = CheckoutStore();
  const { state } = storeToRefs(store);

  const nodeList = computed(() => {
    return state.value.nodeList;
  });

  const serverNodeList = computed(() => {
    return nodeList.value.map((e) => new IServerNode(e));
  });

  /**
   *
   * @deprecated
   * @param id
   * @returns
   */
  const findNodeById = (id: number) => {
    return nodeList.value.find((e) => e.id === id);
  };

  const getNodeById = (id: number) => {
    const node = nodeList.value.find((e) => e.id === id);
    return node ? new IServerNode(node) : undefined;
  };

  const getServerNodeList = async () => {
    if (serverNodeList.value.length === 0) {
      await store.updateNodeList();
    }

    return markRaw(serverNodeList.value);
  };

  return {
    nodeList,
    serverNodeList,
    findNodeById,
    getNodeById,
    getServerNodeList,
  };
};
