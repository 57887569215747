import BigNumber from 'bignumber.js';
import { PropType, computed, defineComponent } from 'vue';
import { NumberFixed, strFilter, useToggle } from '../../../../utils';
import { getConfig } from '../../../../configs';
import { useNetworkStore, useTokenClassStore } from '../../../../stores/checkout.store';
import { IFeeInfoAbstract } from '../../../../factory/strategics/interface';
import { Link as DLink } from '../../../../ui/components';
import './index.less';

export default defineComponent({
  name: 'TxFeeInfo',
  components: {
    DLink,
  },
  props: {
    chainId: {
      type: Number,
      required: true,
    },
    feeInfo: {
      type: Object as PropType<IFeeInfoAbstract>,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const tokenClassStore = useTokenClassStore();

    const txFeeInfo = computed(() => props.feeInfo);
    const estimatedPrice = computed(() => {
      return NumberFixed(txFeeInfo.value.estimatedFeeUsd, 4);
    });
    const networkStore = useNetworkStore();

    const dapposNetwork = computed(() => {
      if (props.chainId) {
        return networkStore.getEvmChainsParameter(props.chainId);
      }
      return undefined;
    });

    const tokenLogo = computed(() => {
      const config = getConfig();
      return props.feeInfo?.gasToken?.address ? tokenClassStore.getTokenClass(props.feeInfo?.gasToken!.address, props.chainId)?.icon : `${config.dappOS_CDN}/tokenIcon/${txFeeInfo.value?.gasToken?.symbol?.toLowerCase()}.png`;
    });

    const gwei = computed(() => {
      return NumberFixed(new BigNumber(props.feeInfo?.gwei ?? 0).div(1e9), 6);
    });
    const serviceInfo = computed(() => {
      return {
        service: strFilter(txFeeInfo.value.service),
        link: `${dapposNetwork.value?.blockExplorerUrl}/address/${txFeeInfo.value.service}`,
      };
    });

    // const priorityFee = computed(() => {
    //   if (!txFeeInfo.value.gasToken) return '0';
    //   return NumberFixed(txFeeInfo.value.gasToken.formatUnits(txFeeInfo.value.priorityFee));
    // });
    const { isOpen, toggle } = useToggle(props.open);
    return () => (
      <>
        <div class='dappos-gas-table-th'>
          <div class='dappos-flex dappos-items-center dappos-gap-2'>
            <img src={dapposNetwork.value?.iconUrl} alt={dapposNetwork.value?.chainName} class='dappos-icon' />
            <span>{dapposNetwork.value?.chainName} </span>
          </div>
          <div>
            <div class='dappos-flex dappos-items-center dappos-gap-4'>
              {txFeeInfo.value?.gasToken ? (
                <>
                  <img src={tokenLogo.value} alt={txFeeInfo.value?.gasToken?.symbol} class='dappos-icon' />
                  {txFeeInfo.value?.gasToken?.symbol ?? 'Unknown'}
                </>
              ) : (
                'Unknown'
              )}
            </div>
          </div>
          <div class='dappos-hidden-md'>{txFeeInfo.value?.gasLimit}</div>
          <div class='dappos-hidden-md'>
            <span> {gwei.value} GWEI </span>
          </div>

          <div class='dappos-gas-fee-col'>
            <div>${estimatedPrice.value}</div>

            {isOpen.value ? <icon-material-symbols-keyboard-arrow-down-rounded class='dappos-cursor-pointer' onClick={() => toggle()} /> : <icon-material-symbols-keyboard-arrow-up class='dappos-cursor-pointer' onClick={() => toggle()} />}
          </div>
        </div>

        <div
          class={{
            'dappos-gas-fee-item-desc': true,
            'dappos-hidden': isOpen.value,
          }}
        >
          <div class='dappos-fee-detail-item'>
            <div class='dappos-fee-detail-label'> Gas Token </div>

            <div class='dappos-fee-detail-content'>
              {txFeeInfo.value?.gasToken ? (
                <>
                  <img src={tokenLogo.value} alt={txFeeInfo.value?.gasToken?.symbol} class='dappos-icon' />
                  {txFeeInfo.value?.gasToken?.symbol ?? 'Unknown'}
                </>
              ) : (
                'Unknown'
              )}
            </div>
          </div>

          <div class='dappos-fee-detail-item'>
            <div class='dappos-fee-detail-label'> Gas Price </div>

            <div class='dappos-fee-detail-content'>
              <span> {gwei.value} (GWEI) </span>
            </div>
          </div>

          <div class='dappos-fee-detail-item'>
            <label for='gasLimitInput' class='dappos-fee-detail-label'>
              Gas Limit
            </label>
            <input id='gasLimitInput' type='text' readonly class='dappos-fee-detail-content' value={txFeeInfo.value.gasLimit} />
          </div>

          <div class='dappos-fee-detail-item'>
            <div class='dappos-fee-detail-label'> Service </div>

            <div class='dappos-fee-detail-content'>
              <DLink href={serviceInfo.value.link} target='_blank' rel='noopener noreferrer'>
                <span> {serviceInfo.value.service} </span>
              </DLink>
            </div>
          </div>
        </div>
      </>
    );
  },
});
