import { defineComponent, toRefs } from 'vue';

export default defineComponent({
  name: 'DappOSLink',
  props: {
    href: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '_blank',
    },
    rel: {
      type: String,
      default: '',
    },
  },

  setup(props, { slots }) {
    const { href, disabled, target, rel } = toRefs(props);

    return () => (
      <>
        {disabled.value ? (
          <span class={{ disabled: disabled, 'dappos-link': true }}>{slots.default?.()}</span>
        ) : (
          <a href={href.value} class="dappos-link" target={target.value} ref={rel.value}>
            {slots.default?.()}
          </a>
        )}
      </>
    );
  },
});
