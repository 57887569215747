import { ethers } from 'ethers';
import { RetryProvider, getRetryProvider } from 'retry-provider';
import { useNetworkStore } from '../stores/checkout.store';

interface IProviderParams {
  chainId: number;
  rpcUrl?: string;
  rpcUrls?: string[];
}

/**
 * getProvider
 * @description get custom provider
 * @returns RetryProvider
 */
const provider_cache = new Map<number, ethers.providers.Provider>();

function getProvider(chainId: number) {
  return provider_cache.get(chainId);
}

async function getProviderAsync(opt: IProviderParams): Promise<ethers.providers.Provider> {
  const { chainId, rpcUrl } = opt;
  const _p = provider_cache.get(chainId);
  if (_p) {
    return _p;
  }
  const networkStore = useNetworkStore();
  const rpcs = rpcUrl ? [rpcUrl] : (await networkStore.getNetworkByChainId(chainId)).evmChainParameter.rpcUrls;
  if (rpcs.length) {
    await setProvider({
      chainId: chainId,
      rpcUrls: rpcs,
    });
  }
  const newProvider = provider_cache.get(chainId);
  if (newProvider) {
    return newProvider;
  }
  throw new Error('provider not found');
}

async function setProvider(opt: IProviderParams): Promise<RetryProvider> {
  const { chainId, rpcUrls } = opt;

  const network = {
    chainId: Number(chainId),
    name: 'unknown',
  };

  const rpcUrlSort = (rpcUrls ?? []).sort((a, b) => {
    try {
      if (window?.location?.host?.includes('dappos.')) return 0;
    } catch (error) {
      console.error(error);
    }
    return Number(/ankr\.com|infura/.test(a)) - Number(/ankr\.com|infura/.test(b));
  });
  const _provider = getRetryProvider(rpcUrlSort, {
    network: network,
    backoffOptions: {
      maxDelay: 1000,
      numOfAttempts: 3,
      startingDelay: 100,
      timeMultiple: 1.5,
    },
    providerRequestTimeout: 5 * 1000,
  });
  provider_cache.set(Number(chainId), _provider);
  return _provider;
}

function hasProvider(chainId: number) {
  return provider_cache.has(chainId);
}

export { getProvider, hasProvider, setProvider, getProviderAsync };
