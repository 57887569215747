import { ConfigService, ConfigEnvironment } from '@dappos/sdk-core';

const getConfig = () => {
  return ConfigService.getInstance().getConfig();
};

const setEnv = (env: ConfigEnvironment | string) => {
  ConfigService.getInstance().setEnv(env);
};

export { setEnv, getConfig };
