import { ComputedRef, InjectionKey } from 'vue';

export interface InjectState {
  props: ComputedRef<{
    wallet: boolean;
    size: number;
    position?: string;
  }>;
  events: {
    versionChange: (v: string) => void;
    disconnect: () => void;
    deposit: () => void;
  };
}
export const RuntimeContextKey = Symbol() as InjectionKey<InjectState>;
