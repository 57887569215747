import { ConfigService, DappOSOrderData } from '@dappos/sdk-core';
import { DappOSPolyFactory, Solution } from '@dappos/v2-sdk';
import { HybridPayTransactionService, HybridPayTransactionServiceParam, HybridPaySolution } from '@dappos/v2-sdk';
import { ethers } from 'ethers';
import { ErrorReject, NodeFilterLog } from '~/constants/error';
import { Vault_Info } from '~/contracts/vault-info';
import { DappOSCheckoutInstance } from '~/core';
import { Strategy } from '~/factory/strategics/strategy';
import { AccountStore, useCheckoutStore, useNodeInfoStore } from '~/stores';
import { ITransactionType, useRuntimeStore } from '~/stores/runtime.store';
import { getProviderAsync, logger } from '~/utils';
// import { HybridPayTransactionService, HybridPayTransactionServiceParam, HybridPaySolution } from '../../../../v2-sdk/src';

export interface ISimulateHybridExecuteResult {
  nodeId?: number;
  node: boolean;
  canToEOA: boolean;
  nodeFailureReasons: NodeFilterLog[];
  createPays: {
    amountIn: string;
    amountOut: string;
    tokenOut: {
      address: string;
      decimal: number;
      symbol: string;
    };
    tokenIn: {
      address: string;
      decimal: number;
      symbol: string;
    };
  }[];
  combinAccounts: {
    chainId: number;
    feeInfo: {
      estimatedFee: string;
      estimatedFeeUsd: string;
      gasToken: {
        symbol: string;
        address: string;
      };
    };
  }[];
  estimatedFeeRaw: string;
  estimatedFeeUsd: string;
  estimatedFee: string;
  solution: HybridPaySolution;
}

export default class HybridTransactionStrategy extends Strategy {
  constructor() {
    super({
      name: 'Hybrid Transaction Service',
      type: ITransactionType.hybrid,
    });
  }

  async execute(param: HybridPayTransactionServiceParam) {
    logger.info('Transaction Service Execute', param);
    const runtimeStore = useRuntimeStore();
    const {
      runtimeState: { state },
      modalCtrl,
      promiseCtrl,
    } = runtimeStore;
    state.value.orderType = ITransactionType.hybrid;
    state.value.params = param;
    modalCtrl.openModal();

    this._awaitConfirmPromise = new Promise<DappOSOrderData>((resolve, reject) => {
      promiseCtrl.resolve = resolve;
      promiseCtrl.reject = reject;
    }).finally(() => {
      runtimeStore.resetState();
      modalCtrl.closeModal();
    });
    // reject retry
    this._executePromise = this._execute(param).catch((e) => {
      console.error(e);
      throw e;
    });
    return this._awaitConfirmPromise;
  }
  private async _execute(param: HybridPayTransactionServiceParam) {
    const { getServerNodeList } = useNodeInfoStore();
    const nodeList = await getServerNodeList();
    const accountStore = AccountStore();
    const { checkoutState } = useCheckoutStore();
    const aStore = {
      accountList: accountStore.accountList,
      accountChainId: accountStore.chainId,
      assets: accountStore.assets,
    };
    const polyFactory = new DappOSPolyFactory(checkoutState.state.value.networkListState, checkoutState.state.value.tokenWhiteList, aStore);

    const service = new HybridPayTransactionService(polyFactory, nodeList);

    const result = await service.estimatedIntent(param, {
      chainId: accountStore.chainId!,
      connector: accountStore.connector.name,
      app: DappOSCheckoutInstance.getInstance().metadata?.name ?? '',
    });

    const runtimeStore = useRuntimeStore();
    const {
      runtimeState: { state },
    } = runtimeStore;

    state.value.solution = result.solution as Required<Solution>;
    state.value.fallbackSolutions = [];

    // verify solution
    await (async () => {
      const solution = state.value.solution;
      let isVerified = false;
      const node = solution?.serverNode;
      const vaultInfoAddress = ConfigService.getInstance().getConfig().dappOS_VaultInfo;
      if (node && vaultInfoAddress) {
        const provider = await getProviderAsync({ chainId: 56 });
        const vaultInfo = Vault_Info.init(vaultInfoAddress, provider);

        const params = <const>[node.id.toString(), solution.txs.map((e) => e.vw?.chainId).filter(Boolean) as number[]];
        const vaultInfos = await vaultInfo
          .getVaults(...params)
          .catch(() => vaultInfo.getVaults(...params))
          .catch((e) => {
            logger.error(e);
            return [];
          });
        isVerified = solution.txs
          .filter((e) => {
            const rules = [];
            if (solution.orderType.isolateExecute || solution.orderType.isolatePayment) {
              rules.push(!!e.vw);
            }

            rules.push(e.createPays?.length > 0 && e.createPays[0]?.vaultAddress !== ethers.constants.AddressZero);
            return rules.every(Boolean);
          })
          .every((e) => vaultInfos.find((v) => v.vaultAddress === e.createPays[0].vaultAddress?.toLowerCase()));

        solution.orderState.verify = isVerified;
        if (!isVerified && !solution.orderType.isolateExecute) {
          solution.orderState.failureReason = 'This node has not been verified.';
          solution.orderState.isDisabled = true;
        }
      }
    })();
  }

  public async estimatedMaxIntent(param: HybridPayTransactionServiceParam) {
    const result = await estimatedMaxIntent(param);
    return result;
  }

  public async confirm(): Promise<unknown> {
    const runtimeStore = useRuntimeStore();
    const { promiseCtrl } = runtimeStore;

    this._confirmPromise = this._confirm()
      .then((e) => {
        promiseCtrl.resolve(e);
        return e;
      })
      .catch((e) => {
        logger.error(e);
        promiseCtrl.reject({
          ...ErrorReject,
          body: e,
        });
      });
    return this._confirmPromise;
  }

  public async _confirm() {
    const runtimeStore = useRuntimeStore();
    const {
      runtimeState: { state },
      promiseCtrl,
    } = runtimeStore;

    const accountStore = AccountStore();
    if (!accountStore.owner) {
      throw new Error('owner is null');
    }
    if (!accountStore.chainId || !accountStore.dappOSContracts) throw new Error(`unsupported chainId ${accountStore.chainId}`);

    const { solution } = state.value;

    const params = state.value.params as Required<HybridPayTransactionServiceParam>;
    logger.info('execute', params, solution);
    const { getServerNodeList } = useNodeInfoStore();
    const nodeList = await getServerNodeList();
    const { checkoutState } = useCheckoutStore();
    const aStore = {
      accountList: accountStore.accountList,
      accountChainId: accountStore.chainId,
      assets: accountStore.assets,
    };
    const polyFactory = new DappOSPolyFactory(checkoutState.state.value.networkListState, checkoutState.state.value.tokenWhiteList, aStore);

    const service = new HybridPayTransactionService(polyFactory, nodeList);
    const signer = accountStore.getSigner();
    const checkoutInstance = DappOSCheckoutInstance.getInstance();
    const eoaChainId = accountStore.chainId!;
    try {
      solution.txs = HybridPayTransactionService.generateTxs(
        {
          solution: solution,
          eoaChainId,
          app: checkoutInstance.metadata?.name ?? '',
          connector: accountStore.connector.name,
        },
        params,
      );
    } catch (error) {
      console.error(error);
      throw new Error(`generate txs error ${error}`);
    }
    logger.info('Transaction Service executeSolution', params, solution);
    const result = await service
      .executeSolution(solution, params, {
        signer: signer,
        chainId: accountStore.chainId,
        provider: accountStore.provider!,
      })
      .catch((e) => {
        logger.error(e);
        promiseCtrl.reject({
          ...ErrorReject,
          body: e,
        });
        throw e;
      });

    return result;
  }

  public async estimatedIntent(param: HybridPayTransactionServiceParam) {
    const result = await estimatedIntent(param);
    return result;
  }

  /**
   * simulate
   * @deprecated
   */
  public async simulate(param: HybridPayTransactionServiceParam): Promise<ISimulateHybridExecuteResult> {
    const estimatedResult = await estimatedIntent(param);
    const bestSolution = estimatedResult.solution;

    const combinAccounts = [];

    if (bestSolution.destinationTx.gasToken) {
      combinAccounts.push({
        chainId: bestSolution.destinationTx.chainId,
        feeInfo: {
          estimatedFee: bestSolution.destinationTx.gasToken.gasTokenAmount,
          estimatedFeeUsd: bestSolution.destinationTx.gasToken.estimatedFeeUSD,
          gasToken: {
            symbol: bestSolution.destinationTx.gasToken.gasToken.symbol ?? '',
            address: bestSolution.destinationTx.gasToken.gasToken.address,
          },
        },
      });
    }

    const failureReasons = bestSolution.orderState?.failureReason
      ? [
          {
            code: 2001,
            message: bestSolution.orderState.failureReason,
          },
        ]
      : [];

    const createPays = (bestSolution.destinationTx.createPays ?? []).map((e) => {
      return {
        amountIn: e.amountIn,
        amountOut: e.amountOut,
        tokenIn: {
          address: e.tokenIn.address,
          symbol: e.tokenIn.symbol ?? '',
          decimal: e.tokenIn.decimals,
        },
        tokenOut: {
          address: e.tokenOut.address,
          symbol: e.tokenOut.symbol ?? '',
          decimal: e.tokenOut.decimals,
        },
      };
    });

    const canToEoa = !!(createPays.length === param.intentTokens.length && bestSolution.destinationTx.gasToken);

    const { estimatedFee, estimatedFeeRaw } = (() => {
      if (bestSolution.destinationTx.gasToken) {
        const gasTokenAmount = bestSolution.destinationTx.gasToken.gasTokenAmount;
        const gasFee = bestSolution.destinationTx.gasToken.gasToken.parseUnits(gasTokenAmount);
        return {
          estimatedFee: gasTokenAmount,
          estimatedFeeRaw: gasFee,
        };
      }
      return {
        estimatedFee: '0',
        estimatedFeeRaw: '0',
      };
    })();
    return {
      createPays,
      combinAccounts: combinAccounts,
      node: !!bestSolution?.serverNode?.id,
      nodeId: bestSolution?.serverNode?.id,
      canToEOA: canToEoa,
      nodeFailureReasons: failureReasons,
      estimatedFeeUsd: bestSolution.estimatedFeeUSD,
      estimatedFee,
      estimatedFeeRaw,
      solution: bestSolution,
    };
  }
}

async function estimatedMaxIntent(param: HybridPayTransactionServiceParam) {
  logger.info('Transaction Service estimatedMaxIntent', param);
  const { getServerNodeList } = useNodeInfoStore();
  const nodeList = await getServerNodeList();
  const accountStore = AccountStore();
  const { checkoutState } = useCheckoutStore();
  const aStore = {
    accountList: accountStore.accountList,
    accountChainId: accountStore.chainId,
    assets: accountStore.assets,
  };
  const polyFactory = new DappOSPolyFactory(checkoutState.state.value.networkListState, checkoutState.state.value.tokenWhiteList, aStore);
  const service = new HybridPayTransactionService(polyFactory, nodeList);
  const result = await service.estimatedMaxIntent(param);
  return result;
}

async function estimatedIntent(param: HybridPayTransactionServiceParam) {
  logger.info('Transaction Service estimatedIntent', param);
  const { getServerNodeList } = useNodeInfoStore();
  const nodeList = await getServerNodeList();
  const accountStore = AccountStore();
  const { checkoutState } = useCheckoutStore();
  const aStore = {
    accountList: accountStore.accountList,
    accountChainId: accountStore.chainId,
    assets: accountStore.assets,
  };
  const polyFactory = new DappOSPolyFactory(checkoutState.state.value.networkListState, checkoutState.state.value.tokenWhiteList, aStore);

  const service = new HybridPayTransactionService(polyFactory, nodeList);

  const result = await service.estimatedIntent(param, {
    chainId: accountStore.chainId!,
    connector: accountStore.connector.name,
    app: DappOSCheckoutInstance.getInstance().metadata?.name ?? '',
  });
  return result;
}
