import { DappOSInstitutionalApi, DappOSApi, DappOSRpc } from '@dappos/sdk-core';

// TODO: use sdk-core to request
export const getSuperNodeConnection = () => {
  const connection = DappOSRpc.getInstance();
  return connection;
};

export const getSuperNodeAdminConnection = () => {
  const connection = DappOSApi.getInstance();
  return connection;
};

export const getDappOSCDNConnection = () => {
  const connection = DappOSApi.getInstance();
  return connection;
};

export const getInstitutionalApi = () => {
  const connection = DappOSInstitutionalApi.getInstance();
  return connection;
};
