import { defineComponent, provide, onBeforeMount, KeepAlive } from 'vue';
import Confirm from '../confirm';
import FeeView from '../vw-fee-info';

import NodeList from '../node-list';
import TxHeader from './header';
import { CheckoutRouterViewEnum, useRuntimeStore, RuntimeProvideKey, RuntimeState } from '../../../../stores/runtime.store';
import { logger } from '../../../../utils/logger';

export default defineComponent({
  name: 'TxSendTransaction',
  components: {
    TxHeader,
    Confirm,
    FeeView,
    NodeList,
  },
  setup() {
    const { route, awaitTransaction, runtimeState } = useRuntimeStore();
    onBeforeMount(() => {
      awaitTransaction.execute(0).catch((e) => {
        logger.debug(e);
      });
    });
    provide(RuntimeProvideKey, runtimeState.state.value as RuntimeState);
    return () => (
      <div class='dappos-container'>
        <TxHeader />
        <KeepAlive exclude='TxGasFee'>
          {route.value.view === CheckoutRouterViewEnum.Index && <Confirm />}
          {route.value.view === CheckoutRouterViewEnum.FeeView && <FeeView />}
          {route.value.view === CheckoutRouterViewEnum.BridgeFeeView && <NodeList />}
        </KeepAlive>
      </div>
    );
  },
});
