export const UserCloseModal = {
  code: 4001,
  message: 'User close modal',
};

export const UserReject = {
  code: 4002,
  message: 'User reject',
};

export const ErrorReject = {
  code: 4004,
  message: 'Error reject',
};

export const UnstableNetwork = {
  code: 404,
  message: 'Unstable network. Please check and try again.',
};

export const ApproveFailed = {
  code: 4005,
  message: 'token approve failed',
};

export const NodeVaultNotFound = {
  code: 2001,
  message: 'No node currently supports the token, please switch the token type.',
};
export const NodeVaultBalanceNotEnough = {
  code: 2002,
  message: 'The amount is too large, there is no matching node, please adjust it and try again.',
};
export const NodeFeeNotFound = {
  code: 2004,
  message: 'There is no matching node, please replace the gas token and try again.',
};
export const NodeNotFound = {
  code: 2005,
  message: 'There is no matching node.',
};

export type NodeFilterLog = typeof NodeVaultNotFound | typeof NodeVaultBalanceNotEnough | typeof NodeFeeNotFound | typeof NodeNotFound;
