import { defineComponent, computed, inject, ref } from 'vue';
import { requestVw, useWalletLiteState } from '~/stores/wallet-lite.store';
import { ConfigService } from '@dappos/sdk-core';
import dAccountItem from './d-account-item';
import { RuntimeContextKey } from '~/components/wallet-lite/common';
import osLogo from '../../../assets/icon/os-logo.svg';

import copy_address from '../../../assets/icon/copy_address.svg';
import disconnect from '../../../assets/icon/disconnect_red.svg';
import copyok from '../../../assets/icon/copy_ok.svg';

import { useClipboard } from '@vueuse/core';
import { Connector } from '~/components/wallet-lite/wallet-lite.ce';

export default defineComponent({
  name: 'WalletLiteContent',
  components: {
    dAccountItem,
  },
  props: {
    connector: {
      type: Object as () => Connector,
      required: true,
    },
  },
  setup(_props) {
    const source = ref('');
    const isSelect = ref(false);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const runtimeInject = inject(RuntimeContextKey)!;
    const contextProps = computed(() => runtimeInject.props.value);
    const { copy, copied, isSupported } = useClipboard({ source });

    const { virtualWalletState, state, setVersion, version: activeVersion } = useWalletLiteState();

    const dAccountList = computed(() => {
      if (state.value.vwInfos.length === 0) {
        return [];
      } else {
        console.log('before map', state.value.vwInfos);
        return state.value.vwInfos
          .map((e: any) => {
            if (e && state.value.config[e.chainId]) {
              return {
                ...e,
                blockExplorerUrl: state.value.config[e.chainId]?.blockExplorerUrl,
                iconUrl: state.value.config[e.chainId]?.iconUrl,
                chainName: state.value.config[e.chainId]?.chainName,
              };
            }
          })
          .filter((e) => {
            if (e) return state.value.supportDstChains.includes(e.chainId);
          });
      }
    });

    const isDstIncludes = computed(() => {
      const includesResult: number[] = [];
      for (let index = 0; index < state.value.chainsToCreateVw.length; index++) {
        const chainToCreateVw = state.value.chainsToCreateVw[index];
        if (state.value.supportDstChains.includes(chainToCreateVw)) {
          includesResult.push(chainToCreateVw);
        }
      }
      return includesResult;
    });

    // const totalTvl = computed(() => {
    //   return dAccountList.value.reduce((pre, cur) => {
    //     return pre.plus(cur.total ?? 0);
    //   }, new BigNumber(0));
    // });

    const handVersionChange = (version: string) => {
      setVersion(version);
      runtimeInject.events.versionChange(version);
    };

    const handDisConnect = () => {
      runtimeInject.events.disconnect();
    };

    const addressFilter = () => {
      let adr = state.value.owner;
      if (!adr || adr.length < 10) return;
      adr = adr.toLocaleUpperCase();
      return `${adr.substring(0, 4)}...${adr.substring(adr.length - 4, adr.length)}`;
    };

    const copyAddress = () => {
      copy(state.value.owner);
      isSelect.value = true;
      setTimeout(() => {
        isSelect.value = false;
      }, 3000);
    };

    const openDeposit = () => {
      const url =
        ConfigService.getInstance().getConfig().dappOS_Wallet +
        // 'https://testcicd-wallet.dappos.finance' +
        '/deposit?' +
        'connector=' +
        _props.connector.name +
        '&address=' +
        state.value.owner +
        '&chainId=' +
        (isCubist() ? '42161' : _props.connector.chaindId);

      runtimeInject.events.deposit();

      window.open(url, '_blank');
    };

    const requestVwClick = () => {
      requestVw(state.value.owner as any);
    };

    const isCubist = () => {
      const isCubist = _props.connector.name == 'twitter' || _props.connector.name === 'google' || _props.connector.name === 'facebook';
      return isCubist;
    };

    const openAsset = () => {
      const url =
        ConfigService.getInstance().getConfig().dappOS_Wallet +
        // 'https://testcicd-wallet.dappos.finance' +
        '/assets?' +
        'connector=' +
        _props.connector.name +
        '&address=' +
        state.value.owner;
      window.open(url, '_blank');
    };

    return () => (
      <div
        class="wallet-lite-content flex block"
        ref={(e) => {
          e && ((e as any).part = 'wallet-lite-content ');
        }}
      >
        {state.value.owner && (
          <>
            {virtualWalletState.isLoading.value && (
              <div class="wallet-lite-footer_not-found">
                <icon-eos-icons-loading class="wallet-lite-text-color-secondary" />
              </div>
            )}

            {!virtualWalletState.isLoading.value && (
              <>
                <div class="wallet-lite-account">
                  <div class="wallet-lite-account__header">
                    <div class="updateversion_1_3_setup_1">
                      <div class="logoheadparent">
                        <div class="logohead">
                          <img src={osLogo} class="osLogo" />
                          <div class="walletlogo">
                            <img src={_props.connector.logo} style="object-fit: cover; width: 12px; height: 12px" />
                            {/* <img src={_props.connector.logo} style="object-fit: cover; width: 12px; height: 12px" /> */}
                            {/* <img src={cubistLogo} style="object-fit: cover; width: 12px; height: 12px" /> */}
                          </div>
                        </div>
                        <span style="color:#ffffff;">{addressFilter()}</span>
                      </div>

                      <div style="display:flex">
                        <div
                          style="width:32px;height:32px;margin-left:8px;"
                          class="headaction"
                          onClick={() => {
                            copyAddress();
                          }}
                        >
                          <img src={isSelect.value ? copyok : copy_address} />
                        </div>

                        <div
                          style="width:32px;height:32px;margin-left:8px;"
                          class="headaction"
                          onClick={() => {
                            handDisConnect();
                          }}
                        >
                          <img src={disconnect} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {dAccountList.value.length === 0 ? (
                    isCubist() && isDstIncludes.value.length !== 0 ? (
                      state.value.createVwRequestLoading ? (
                        <>
                          <div class="createvwloading">
                            <icon-eos-icons-loading class="wallet-lite-text-color-secondary" />
                            <span class="wallet-lite-text-color-secondary">create wallet ... </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div class="createVwFailed" onClick={requestVwClick}>
                            <span class="wallet-lite-text-color-secondary">Get address</span>
                          </div>
                        </>
                      )
                    ) : (
                      <>
                        <div class="nodata">
                          <span class="wallet-lite-text-color-secondary">No data</span>
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      {dAccountList.value.map((item: any, index: any) => {
                        return (
                          <>
                            <dAccountItem item={item} index={index} />
                          </>
                        );
                      })}
                    </>
                  )}
                  {}
                </div>
                <div class="wallet-lite-content__footer">
                  <div class="b1" onClick={openDeposit}>
                    <a target="_black">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.1071 4.75079L12.7497 4.74972L12.7523 16.9748L17.4727 12.2544L18.5986 13.4014L11.9289 20.0711L5.25741 13.3995L6.40591 12.2511L11.1096 16.9759L11.1071 4.75079Z"
                          fill="#2AAE67"
                        />
                      </svg>

                      {_props.connector.showRed && <div class="redpoint"></div>}
                      <span>Deposit</span>
                    </a>
                  </div>
                  <div style={{ width: '8px' }}></div>
                  <a onClick={openAsset} target="_blank" class="no-animation flex h-auto flex-1 flex-col justify-start gap-2 rounded-lg py-3 pl-3 font-medium">
                    <svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M22.1389 14.3962H18.0906C16.6042 14.3953 15.3993 13.1914 15.3984 11.705C15.3984 10.2185 16.6042 9.01458 18.0906 9.01367H22.1389"
                        stroke="#2AAE67"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path d="M18.5484 11.6426H18.2368" stroke="#2AAE67" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.24766 3H16.8911C19.7892 3 22.1388 5.34951 22.1388 8.24766V15.4247C22.1388 18.3229 19.7892 20.6724 16.8911 20.6724H8.24766C5.34951 20.6724 3 18.3229 3 15.4247V8.24766C3 5.34951 5.34951 3 8.24766 3Z"
                        stroke="#2AAE67"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path d="M7.53564 7.53906H12.9346" stroke="#2AAE67" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>dappOS Wallet</span>
                  </a>
                </div>
              </>
            )}
          </>
        )}

        {!state.value.owner && (
          <>
            <div class="wallet-lite-footer_not-connected"> no connect</div>
          </>
        )}
      </div>
    );
  },
});
