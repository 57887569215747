import { computed, defineComponent } from 'vue';
import { getConfig } from '../../../../configs';
import { CheckoutRouterViewEnum, useRuntimeStore } from '../../../../stores/runtime.store';
import { Button as DButton } from '../../../../ui/components';

export default defineComponent({
  name: 'TxConfirmHeader',
  components: {
    DButton,
  },
  setup() {
    const logoUrl = computed(() => {
      return getConfig().dappOS_CDN + '/dappLogo/dappos.png';
    });
    const { routeCtrl, route, modalCtrl, rejectDisabled } = useRuntimeStore();
    const routeView = computed(() => route.value.view);
    const back = () => {
      routeCtrl.back();
    };
    return () => (
      <>
        <div class='dappos-checkout-header dappos-text-center'>
          {routeView.value === CheckoutRouterViewEnum.Index && (
            <>
              <div> </div>
              <div class='dappos-header-title'>
                <img src={logoUrl.value} alt='dappOS' />
                <span>dappOS Checkout </span>
              </div>
              <DButton type='ghost' size='xs' class='dappos-p-0 dappos-cursor-pointer' disabled={rejectDisabled.value} onClick={() => modalCtrl.cancel()}>
                <Icon-material-symbols-close-rounded />
              </DButton>
            </>
          )}

          {routeView.value !== CheckoutRouterViewEnum.Index && (
            <>
              <div>
                <DButton type='ghost' size='xs' class='dappos-p-0 dappos-cursor-pointer' onClick={() => back()}>
                  <Icon-material-symbols-arrow-back />
                </DButton>
              </div>
              <div class='dappos-header-title'>
                {routeView.value === CheckoutRouterViewEnum.BridgeFeeView && <span>Bridge Fee Details </span>}
                {routeView.value === CheckoutRouterViewEnum.FeeView && <span> Gas Fee Details </span>}
              </div>
              <DButton type='ghost' size='xs' class='dappos-p-0 dappos-cursor-pointer' disabled={rejectDisabled.value} onClick={() => modalCtrl.cancel()}>
                <Icon-material-symbols-close-rounded />
              </DButton>
            </>
          )}
        </div>
      </>
    );
  },
});
