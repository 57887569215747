import { Transition, defineComponent, watch } from 'vue';
import '../style/index.less';
import './index.less';
import { AccountStore } from '../../stores';
import { useDocumentVisibility, useIntervalFn } from '@vueuse/core';
import { useCheckoutStore } from '../../stores/checkout.store';
import CheckoutTransactionLayout from './transaction/layout';
import RevokeView from './revoke';
import DisabledProviderView from './disabled-provider';
import { useRuntimeStore } from '../../stores/runtime.store';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'DappOSCheckout',
  components: {
    RevokeView,
    DisabledProviderView,
    CheckoutTransactionLayout,
  },
  setup() {
    const accountStore = AccountStore();
    const { checkoutStore } = useCheckoutStore();
    const { open, modalCtrl, revokeOpen, disabledProvider } = useRuntimeStore();

    const intervalUpdate = [
      useIntervalFn(() => {
        accountStore.updateAccountsAsset();
      }, 1000 * 20),
    ];

    const visibility = useDocumentVisibility();

    watch(visibility, (v) => {
      if (v === 'visible') {
        intervalUpdate.forEach((e) => {
          e.resume();
        });
        checkoutStore.resumeUpdate();
      } else {
        intervalUpdate.forEach((e) => {
          e.pause();
        });
        checkoutStore.pauseUpdate();
      }
    });

    const build_time = dayjs(__APP_BUILD_TIME__).format('YYYY-MM-DD HH:mm:ss');
    return () => (
      <>
        <span class='dappos-hidden'>{build_time}</span>
        {revokeOpen.value && (
          <div class='dappos-checkout-modal'>
            <Transition name='dappos-fade' mode='out-in' appear>
              <div class='dappos-modal-musk' aria-hidden='true' onClick={() => modalCtrl.switchRevoke(false)}></div>
            </Transition>
            <Transition name='dappos-scale' mode='out-in' appear>
              <div class='dappos-container-wrapper'>
                <RevokeView />
              </div>
            </Transition>
          </div>
        )}

        {disabledProvider.value && (
          <div class='dappos-checkout-modal'>
            <Transition name='dappos-fade' mode='out-in' appear>
              <div class='dappos-modal-musk' aria-hidden='true'></div>
            </Transition>
            <Transition name='dappos-scale' mode='out-in' appear>
              <div class='dappos-container-wrapper'>
                <DisabledProviderView />
              </div>
            </Transition>
          </div>
        )}

        {open.value && (
          <div class='dappos-checkout-modal'>
            <Transition name='dappos-fade' mode='out-in' appear>
              <div class='dappos-modal-musk' aria-hidden='true' onClick={() => modalCtrl.closeModal}></div>
            </Transition>
            <Transition name='dappos-scale' mode='out-in' appear>
              <div class='dappos-container-wrapper'>
                <CheckoutTransactionLayout />
              </div>
            </Transition>
          </div>
        )}
      </>
    );
  },
});
