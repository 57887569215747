export const strFilter = (adr?: string, start = 6, end = 4) => {
  if (!adr || adr.length < 10) return;
  return `${adr.substring(0, start)}...${adr.substring(adr.length - end, adr.length)}`;
};

export function mapAddressToNumber(address: string, version?: string): number {
  const hash: number = hexAddress(`${address}-v${version ?? ''}`);
  const mappedNumber: number = 1 + (Math.abs(hash) % 100);
  return mappedNumber;
}

function hexAddress(s: string): number {
  return s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

import w1 from '../assets/icon/w1.svg';
import w2 from '../assets/icon/w2.svg';
export function getAvatar(address: string, version = '0') {
  switch (version) {
    case '2':
      return w1;
    case '2.1':
      return w2;
    default:
      return w1;
  }
}
