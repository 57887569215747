import { defineComponent } from 'vue';
import { useRuntimeStore } from '../../../stores';

export default defineComponent({
  name: 'DisabledProvider',
  setup() {
    const { disabledProvider } = useRuntimeStore();

    return () => (
      <>
        <div class='dappos-container dappos-revoke'>
          <div class='dappos-checkout-header dappos-text-center'>
            <div></div>
            <div class='dappos-header-title'>
              <img src='https://dappos-public-resource.s3.amazonaws.com/dappLogo/dappos.png' alt='dappOS' />
              <span> Unable to sign </span>
            </div>
            <div></div>
          </div>
          <div class='dappos-checkout-content'>
            <div class='dappos-flex dappos-flex-col dappos-items-center dappos-justify-center dappos-mb-10'>
              <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fill-rule='evenodd' clip-rule='evenodd' d='M8.46023 42.0579H39.54C42.9586 42.0579 45.1042 38.3629 43.4058 35.3937L27.8778 8.24244C26.1686 5.25396 21.8598 5.2518 20.1484 8.24028L4.59443 35.3917C2.89599 38.3607 5.03957 42.0579 8.46023 42.0579Z' fill='#F8943A' />
                <path d='M23.9946 26.8843V20.1855' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' />
                <path d='M23.978 33.5508H23.9996' stroke='white' stroke-width='3.5' stroke-linecap='round' stroke-linejoin='round' />
              </svg>
              <span class='dappos-text-center' style='letter-spacing: 0;'>
                {typeof disabledProvider.value === 'boolean' ? 'Unable to sign. Please switch to EOA wallet and re-try.' : disabledProvider.value}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  },
});
