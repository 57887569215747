import { type ContractInterface, ethers, BigNumber } from 'ethers';
const DEFAULT_LIMIT = 300000;

export interface WriteOption {
  gasPrice?: number;
  gasLimit?: number;
  value?: string;
}

export type Parameters = (string | number | object | boolean)[];

class BaseContract {
  readonly provider: ethers.providers.Provider;
  readonly signer?: ethers.Signer;
  private readonly _contract: ethers.Contract;
  get contract(): ethers.Contract {
    if (this.signer) {
      return this._contract.connect(this.signer);
    }
    return this._contract;
  }
  constructor(abi: ContractInterface, address: string, provider: ethers.providers.Provider, signer?: ethers.Signer) {
    this._contract = new ethers.Contract(address, abi, provider);

    this.provider = provider;
    if (signer) {
      this._contract = this._contract.connect(signer);
      this.signer = signer;
    }
  }

  async read(method: string, parameters: Parameters = []) {
    const result = await this._contract[method].apply(null, parameters);
    return result;
  }

  async write(method: string, parameters: Parameters, options?: WriteOption): Promise<ethers.providers.TransactionResponse> {
    let _gasLimit;

    if (!options?.gasLimit) {
      try {
        const gasEstimate = await this.contract.estimateGas[method].apply(null, [...parameters, options]);
        // plus 30%
        _gasLimit = gasEstimate.mul(BigNumber.from(100 + 30)).div(BigNumber.from(100));
      } catch (error) {
        _gasLimit = BigNumber.from(DEFAULT_LIMIT);
      }
    } else {
      _gasLimit = BigNumber.from(options.gasLimit);
    }

    const feeOption = Object.assign(options ?? {}, { gasLimit: _gasLimit });
    parameters.push(feeOption);

    const tx: ethers.providers.TransactionResponse = await this.contract[method].apply(null, parameters);
    return tx;
  }
}

export { BaseContract };
