import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '../ui/views';
import { DappOSWrapperElementId } from '../constants';
import { ConfigEnvironment } from '@dappos/sdk-core';

export interface IConfigOption {
  env: ConfigEnvironment | string;
  metadata: IMetaData;
  rpcMaps?: Record<number, string[]>;
}
export interface IMetaData {
  name: string;
  icon?: string;
  url?: string;
  description?: string;
  env?: ConfigEnvironment | string;
}
class DappOSCheckoutInstance {
  private static instance: DappOSCheckoutInstance;
  readonly #AppInstance: ReturnType<typeof createApp>;
  readonly hostElement: HTMLElement;
  metadata: IMetaData | null = null;

  private constructor() {
    const pinia = createPinia();
    const app = createApp(App);
    app.use(pinia);

    document.getElementById(DappOSWrapperElementId)?.remove();
    const hostElement = document.createElement('div');
    hostElement.id = DappOSWrapperElementId;
    document.body.insertAdjacentElement('beforeend', hostElement);

    app.mount(hostElement);
    this.hostElement = hostElement;
    this.#AppInstance = app;
  }

  static getInstance() {
    if (!DappOSCheckoutInstance.instance) {
      DappOSCheckoutInstance.instance = new DappOSCheckoutInstance();
    }
    return DappOSCheckoutInstance.instance;
  }
}

export { DappOSCheckoutInstance };
