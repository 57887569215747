import { computed, defineComponent, inject } from 'vue';
import { RuntimeProvideKey } from '../../../stores/runtime.store';
import '../../style/error-feedback.less';
import { Link as DLink } from '../../../ui/components';

export default defineComponent({
  name: 'ErrorFeedback',
  components: {
    DLink,
  },
  setup() {
    const runtimeInject = inject(RuntimeProvideKey)!;

    const failureReason = computed(() => {
      return runtimeInject.solution?.orderState?.failureReason;
    });
    const errorFeedbackVisible = computed(() => {
      if (!runtimeInject.open) {
        return false;
      }
      return !!failureReason.value;
    });

    return () => (
      <>
        {errorFeedbackVisible.value && (
          <div class='dappos-fail-error'>
            <Icon-tabler-alert-circle-filled class='dappos-icon-alert' />
            <span>{failureReason.value}</span>
          </div>
        )}
      </>
    );
  },
});
