import { computed, defineComponent, inject } from 'vue';
import TxFeeInfo from './item';
import { NumberFixed } from '../../../../utils';
import { ITransactionType, RuntimeProvideKey } from '../../../../stores/runtime.store';
import './index.less';
import BigNumber from 'bignumber.js';
import { DappOSTx } from '@dappos/sdk-core';
import { Token } from '@dappos/v2-sdk';
export default defineComponent({
  name: 'FeeView',
  components: {
    TxFeeInfo,
  },
  setup() {
    const runtimeInject = inject(RuntimeProvideKey)!;

    const txs = computed(() => {
      return [runtimeInject.solution.destinationTx, ...runtimeInject.solution.sourceTxs]
        .filter(Boolean)
        .filter((e, i) => {
          if (runtimeInject.orderType === ITransactionType.hybrid) {
            return i === 0 && e.virtualWallet && e.tx.vw;
          }
          return e.virtualWallet && e.tx.vw;
        })
        .map((e) => {
          const txVW = e.tx.vw as DappOSTx.VW & { _gasToken: Token; gasUsed: string };
          return {
            chainId: e.chainId,
            feeInfo: {
              gwei: e.network.gwei,
              priorityFee: txVW.priorityFee,
              gasTokenPrice: txVW.gasTokenPrice,
              gasUsed: txVW.gasUsed,
              gasLimit: txVW.gasLimit,
              gasToken: e.gasToken?.gasToken ?? txVW._gasToken,
              gasTokenAmount: e.estimatedFeeUSD,
              estimatedFeeUsd: e.estimatedFeeUSD,
              service: txVW.service,
            },
          };
        });
    });

    const estimatedGasFeeUSD = computed(() => {
      const estimatedFeeUsd = runtimeInject.solution.sourceTxs
        .reduce((pre, next) => {
          return pre.plus(next.estimatedFeeUSD);
        }, new BigNumber(0))
        .plus(runtimeInject.solution.destinationTx?.estimatedFeeUSD ?? 0);

      return NumberFixed(estimatedFeeUsd, 4);
    });
    return () => (
      <>
        <div class='dappos-gas-fee dappos-checkout-content'>
          <div class='dappos-gas-table'>
            <div class='dappos-gas-table-tr'>
              <div>Network</div>
              <div>Gas Token</div>
              <div class='dappos-hidden-md'>Gas Limit</div>
              <div class='dappos-hidden-md'>Gas Price</div>
              <div>Estimated gas Fee ($)</div>
            </div>

            {txs.value.map((item) => (
              <>{item.feeInfo && <TxFeeInfo chainId={item.chainId} feeInfo={item.feeInfo} open={txs.value.length !== 1} />}</>
            ))}
          </div>

          <div class='dappos-gas-fee-total'>
            <div> Total </div>
            <div>
              <div>${estimatedGasFeeUSD.value}</div>
              <div class='dappos-text-desc dappos-text-text-sm'></div>
            </div>
          </div>
        </div>
      </>
    );
  },
});
