declare global {
  interface Window {
    Buffer: typeof import('buffer').Buffer;
  }
}

import buffer from 'buffer';

if (typeof window !== 'undefined') {
  window.Buffer = buffer.Buffer;
}
