import { SourceTx } from '@dappos/v2-sdk';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, inject } from 'vue';
import { RuntimeProvideKey } from '../../../../../stores';
import './index.less';
import CombinAccountItem from './item';

export default defineComponent({
  name: 'NodeCombinAccounts',
  components: {
    CombinAccountItem,
  },
  setup() {
    const runtimeInject = inject(RuntimeProvideKey)!;

    const combinAccountList = computed(() => {
      const sourceTxs = cloneDeep(
        ((runtimeInject.solution.sourceTxs ?? []) as SourceTx[]).filter((e) => {
          return e.tx.createPays.length > 0 && (e.virtualWallet || !e.virtualWallet);
        }),
      );

      sourceTxs.sort((a, b) => {
        const isEoa_a = !a.tx.vw;
        const isEoa_b = !b.tx.vw;
        if (isEoa_a != isEoa_b) {
          return isEoa_a ? 1 : -1;
        }
        return 0;
      });
      return sourceTxs.map((e, i) => {
        return {
          ...e,
          hiddenDappOS: i > 0,
        };
      });
    });
    return () => (
      <>
        <div class='dappos-node-combin-accounts-table'>
          <table>
            <thead>
              <tr>
                <th class='dappos-hidden-md'>Pay Account</th>
                {/* <th>Token</th> */}
                <th class='pl-16-mobile'>Input</th>
                <th>Output</th>
                <th>Bridge Fee</th>
              </tr>
            </thead>

            <tbody>
              {combinAccountList.value.map((item) => (
                <CombinAccountItem hiddenDappos={item.hiddenDappOS} combinAccount={item} />
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  },
});
