const namespace = 'checkout';
const enabled = (() => {
  try {
    return process.env?.DAPPOS_DEBUG === 'true' || (typeof localStorage !== 'undefined' && localStorage?.getItem('DEBUG')?.includes(namespace));
  } catch (_) {
    return false;
  }
})();
const prefix = `[${namespace}]`;

export const logger = {
  debug: (message: unknown, ...arg: any) => {
    if (enabled) {
      console.debug(prefix, message, ...arg);
    }
  },
  info: (message: unknown, ...arg: any) => {
    if (enabled) {
      console.info(prefix, message, ...arg);
    }
  },
  error: (message: unknown, error?: unknown) => {
    if (enabled) {
      console.error(prefix, message, error);
    }
  },
};
