import { DappOSOrderData, DappOSTx } from '@dappos/sdk-core';
import { Solution } from '@dappos/v2-sdk';
import { useAsyncState } from '@vueuse/core';
import { defineStore, storeToRefs } from 'pinia';
import { computed, reactive } from 'vue';
import type { InjectionKey, Ref } from 'vue';
import { UserCloseModal, UserReject } from '../constants/error';
import { ServiceParamAbstract } from '@dappos/v2-sdk';
import { StrategyFactory } from '../factory/strategy-factory';
export enum ITransactionType {
  normal = 1,
  hybrid = 2,
  // normal_refactor = 3,
  // create_wallet = 4,
  tokenTransfer = 5,
}

export enum CheckoutRouterViewEnum {
  Index = 'index',
  FeeView = 'fee-edit',
  BridgeFeeView = 'node-list',
}

export interface RuntimeState {
  params: ServiceParamAbstract;
  solution: Required<Solution>;
  fallbackSolutions: Required<Solution>[];
  orderType: ITransactionType;
  open: boolean;
  awaitConfirmPromise: Promise<DappOSTx.TransactionItem[]> | null;
  revokeOpen: boolean;
  disabledProvider: boolean | string;
}
const RuntimeStore = defineStore('RuntimeStore', () => {
  const state = reactive<RuntimeState>({
    params: {} as RuntimeState['params'],
    solution: {} as RuntimeState['solution'],
    fallbackSolutions: [] as RuntimeState['fallbackSolutions'],
    orderType: 0 as ITransactionType,
    open: false,
    awaitConfirmPromise: null,
    revokeOpen: false,
    disabledProvider: false,
  });

  const promiseCtrl = {
    resolve: {} as (value: DappOSOrderData) => void,
    reject: {} as (reason?: any) => void,
  };

  const modalCtrl = {
    openModal: () => {
      state.open = true;
    },
    closeModal: () => {
      state.open = false;
    },
    reject: () => {
      promiseCtrl.reject?.(UserReject);
    },
    cancel: () => {
      promiseCtrl.reject?.(UserCloseModal);
    },
    switchRevoke: (revoke?: boolean) => {
      if (revoke !== undefined) {
        state.revokeOpen = revoke;
        return;
      }
      state.revokeOpen = !state.revokeOpen;
    },
    switchDisabledProvider: (disabled?: boolean) => {
      if (disabled !== undefined) {
        state.disabledProvider = disabled;
        return;
      }
      state.disabledProvider = !state.disabledProvider;
    },
  };

  const route = reactive({
    data: null as unknown,
    view: CheckoutRouterViewEnum.Index,
    history: [CheckoutRouterViewEnum.Index],
  });
  const routeCtrl = {
    push: (view: CheckoutRouterViewEnum, data?: unknown) => {
      route.data = data;
      route.view = view;
      route.history.push(view);
    },
    back: () => {
      if (route.data) route.data = null;
      if (route.history.length <= 1) return;
      route.history.pop();
      route.view = route.history[route.history.length - 1];
    },
    replace(view: CheckoutRouterViewEnum) {
      route.view = view;
      route.history = [view];
    },
    reset() {
      route.view = CheckoutRouterViewEnum.Index;
      route.data = undefined;
      route.history = [CheckoutRouterViewEnum.Index];
    },
  };

  const awaitTransaction = useAsyncState(
    async () => {
      const Strategy = StrategyFactory.getInstance().getStrategy();
      return Strategy._executePromise;
    },
    undefined,
    { immediate: false },
  );
  const retry = async () => {
    const Strategy = StrategyFactory.getInstance().getStrategy();
    Strategy.execute(state.params as RuntimeState['params']);
    return Strategy._executePromise;
  };
  const confirmTransaction = useAsyncState(
    async () => {
      const Strategy = StrategyFactory.getInstance().getStrategy();
      Strategy.confirm();
      return await Strategy._confirmPromise;
    },
    undefined,
    { immediate: false },
  );
  return {
    state,
    route,

    promiseCtrl,
    routeCtrl,
    modalCtrl,
    retry,
    awaitTransaction,
    confirmTransaction,
  };
});

export const useRuntimeStore = () => {
  const runtimeStore = RuntimeStore();
  const runtimeState = storeToRefs(runtimeStore);

  const { modalCtrl, routeCtrl, promiseCtrl } = runtimeStore;

  const awaitTransaction = {
    isLoading: computed(() => runtimeStore.awaitTransaction.isLoading),
    isReady: computed(() => runtimeStore.awaitTransaction.isReady),
    error: computed(() => runtimeStore.awaitTransaction.error),
    execute: runtimeStore.awaitTransaction.execute,
  };
  const confirmTransaction = {
    isLoading: computed(() => runtimeStore.confirmTransaction.isLoading),
    isReady: computed(() => runtimeStore.confirmTransaction.isReady),
    error: computed(() => runtimeStore.confirmTransaction.error),
    execute: runtimeStore.confirmTransaction.execute,
  };

  const rejectDisabled = computed(() => {
    return [awaitTransaction.isLoading.value, confirmTransaction.isLoading.value].some(Boolean);
  });
  const confirmDisabled = computed(() => {
    const { solution: transactionRaw } = runtimeState.state.value;
    return [awaitTransaction.isLoading.value, confirmTransaction.isLoading.value, awaitTransaction.error.value, transactionRaw.orderState?.isDisabled].some(Boolean);
  });

  const route = computed(() => runtimeStore.route);
  const open = computed(() => runtimeStore.state.open);
  const revokeOpen = computed(() => runtimeStore.state.revokeOpen);
  const disabledProvider = computed(() => runtimeStore.state.disabledProvider);
  function retryExecute() {
    runtimeStore.awaitTransaction.error = undefined;
    runtimeStore.awaitTransaction.isLoading = true;
    runtimeStore.awaitTransaction.isReady = false;
    runtimeStore
      .retry()
      .then(() => {
        runtimeStore.awaitTransaction.error = undefined;
        runtimeStore.awaitTransaction.isLoading = false;
        runtimeStore.awaitTransaction.isReady = true;
      })
      .catch((e) => {
        runtimeStore.awaitTransaction.error = e;
        runtimeStore.awaitTransaction.isLoading = false;
      });
  }
  function resetState() {
    runtimeState.state.value.params = {} as RuntimeState['params'];
    runtimeState.state.value.solution = {} as RuntimeState['solution'];
    runtimeState.state.value.fallbackSolutions = [] as RuntimeState['fallbackSolutions'];
    runtimeState.state.value.orderType = 0 as ITransactionType;
    runtimeState.state.value.open = false;
    runtimeState.state.value.awaitConfirmPromise = null;
    routeCtrl.reset();
  }
  return {
    runtimeState: {
      state: runtimeState.state as Ref<RuntimeState>,
    },
    awaitTransaction: awaitTransaction,
    confirmTransaction: confirmTransaction,
    rejectDisabled,
    confirmDisabled,
    route,
    open,
    revokeOpen,
    disabledProvider,
    modalCtrl,
    routeCtrl,
    promiseCtrl,
    resetState,
    retryExecute,
  };
};

export const RuntimeProvideKey = Symbol() as InjectionKey<RuntimeState>;
