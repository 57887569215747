import { ICommonParam, VirtualWallet, genOrderId, packCommonParam, PayDBContract } from '@dappos/v2-sdk';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { AddressZero } from '../../constants';
import { ApproveFailed } from '../../constants/error';
import { TransactionGenerate } from '../../core/wallet';
import { AccountStore } from '../../stores';
import { autoApproveErc20Token, concatData, generateOrderHash, getSuperNodeConnection, getVerifyingContract } from '../../utils';
import * as uuid from 'uuid';

interface _IExecuteIsolateOrderETHParam {
  app: string;
  text: string;
  expTime: number;
  service: string;
  commonParam?: ICommonParam;
  executeOrderParams: {
    amount: string;
    token: string;
  }[];
  receiver: string;
  data: string;
  gasLimit: string;
  virtualWallet: VirtualWallet;
  isGateWay: boolean;
  signature?: boolean;
}

async function _executeIsolateOrderETH(opt: _IExecuteIsolateOrderETHParam) {
  const { expTime, virtualWallet, executeOrderParams } = opt;

  const accountStore = AccountStore();
  const signer = accountStore.$state.ownerSigner;
  if (!signer) {
    throw new Error('signer is not found');
  }

  const commonParam = {
    gasToken: opt.commonParam?.gasToken ?? AddressZero,
    action: opt.commonParam?.action ?? 0,
    remainGas: opt.commonParam?.remainGas ?? '0',
  };

  const code = genOrderId({
    expTime,
    action: 1,
    flag: 1,
    srcChain: virtualWallet.chainId,
    dstChain: virtualWallet.chainId,
    salt: `${virtualWallet.owner}-${virtualWallet.manager}-${Math.random().toString()}-${uuid.v4()}`,
  });

  const txPayments = executeOrderParams.map((e, i) => {
    const payOrderId = genOrderId({
      expTime,
      action: 1,
      flag: 1,
      srcChain: virtualWallet.chainId,
      dstChain: virtualWallet.chainId,
      salt: `${virtualWallet.owner}-${virtualWallet.manager}-${i}`,
    });
    return {
      payOrderId: payOrderId,
      vaultAddress: virtualWallet.owner,
      from: {
        chainId: virtualWallet.chainId,
        amount: e.amount,
        token: e.token,
      },
      to: {
        chainId: virtualWallet.chainId,
        amount: e.amount,
        token: e.token,
      },
      bridgeFee: '0',
    };
  });

  const cparam = packCommonParam(commonParam);

  const order = new TransactionGenerate({
    virtualWallet: virtualWallet,
    vw: {
      app: opt.app,
      text: opt.text,
      code: code,
      data: concatData([cparam, opt.data]),
      service: opt.service,
      isGateWay: opt.isGateWay,
    },
    gasLimit: opt.gasLimit,
    gasTokenPrice: '0',
    gasToken: AddressZero,
    priorityFee: '0',
    executePays: txPayments,
    to: {
      chainId: virtualWallet.chainId,
      address: opt.receiver,
    },
  });

  const paydb = PayDBContract.init(virtualWallet.contracts.paydb, signer);

  let serviceSignature = '0x';

  if (opt.signature) {
    const verifyingContract = await getVerifyingContract(virtualWallet.chainId);
    serviceSignature = await order.generateSignature({
      chainId: virtualWallet.chainId,
      verifyingContract: verifyingContract,
      signer,
    });
    order.signature = serviceSignature;
  }

  const vw = order.vw;
  const vwExecuteParamStruct = {
    code: code,
    service: vw.service,
    data: vw.data,
    gasToken: vw.gasToken,
    gasTokenPrice: vw.gasTokenPrice,
    priorityFee: vw.priorityFee,
    gasLimit: vw.gasLimit,
    serviceSignature: serviceSignature,
    proof: [],
    manager: vw.manager,
    feeReceiver: vw.owner,
    isGateway: !!vw.isGateWay,
  };

  const _createOrderParam =
    order.executePaysGenerator?.orderParamStruct.map((e) => {
      return {
        payOrderId: e.payOrderId,
        amountOut: e.amountOut,
        tokenOut: e.tokenOut,
      };
    }) ?? [];

  const createOrdersETH = _createOrderParam.filter((e) => e.tokenOut === AddressZero);
  const ethValue =
    createOrdersETH.length > 0
      ? createOrdersETH
          .map((e) => ethers.utils.parseUnits(e.amountOut, 0))
          .reduce((pre, next) => pre.add(next))
          .toString()
      : '0';

  const approved = await autoApproveErc20Token(
    _createOrderParam.filter((e) => e.tokenOut !== AddressZero).map((e) => ({ address: e.tokenOut, amount: e.amountOut })),
    paydb.address,
    true,
  );
  if (!approved) {
    throw ApproveFailed;
  }

  const walletAddress = virtualWallet.walletCreated ? virtualWallet.address : virtualWallet.owner;

  const params = [opt.receiver, walletAddress, _createOrderParam, vwExecuteParamStruct] as const;
  const gasUsed = await paydb.estimateGas.executeIsolateOrderETH(...params).catch((_) => vwExecuteParamStruct.gasLimit);

  const executeIsolateOrderTx = await paydb.executeIsolateOrderETH(...params, { value: ethValue ?? '0', gasLimit: new BigNumber(gasUsed.toString()).times(1.2).toFixed(0) });
  await executeIsolateOrderTx.wait();
  const hash = executeIsolateOrderTx.hash;
  order.hash = hash;

  if (order.executePaysGenerator) {
    order.executePaysGenerator.srcHash = hash;
    order.executePaysGenerator.dstHash = hash;
  }

  order.state = 3;
  const tx = order.generateTx();

  const _orderHash = generateOrderHash([virtualWallet.owner, serviceSignature, tx.executePays.map((e) => e.payOrderId).join('-'), hash, Math.random().toString()]);
  const orderData = {
    txs: [
      {
        ...tx,
        id: 0,
        executePays: tx.executePays,
        dependenceIds: [],
      },
    ],
    orderHash: _orderHash,
    sender: virtualWallet.owner,
  };
  console.log(orderData);

  await getSuperNodeConnection().sendOrderData(orderData);
  return orderData;
}

type IExecuteIsolateOrderETHParam = Omit<_IExecuteIsolateOrderETHParam, 'signature'>;

async function executeIsolateOrderETH(param: IExecuteIsolateOrderETHParam) {
  return await _executeIsolateOrderETH({ ...param, signature: true });
}
export { executeIsolateOrderETH, _executeIsolateOrderETH };
export type { IExecuteIsolateOrderETHParam };
