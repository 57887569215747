import { PropType, computed, defineComponent, toRefs } from 'vue';
export type ButtonType = 'primary' | 'success' | 'warning' | 'danger' | 'info' | 'text' | 'default' | 'ghost' | 'outline';

export default defineComponent({
  name: 'DappOSButton',
  props: {
    type: {
      type: String as PropType<ButtonType>,
      default: 'default',
    },
    size: {
      type: String,
      default: 'md',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    click: (e: MouseEvent) => true,
  },
  setup(props, { emit, attrs, slots }) {
    const { loading, disabled } = toRefs(props);
    const className = computed(() => {
      return [
        'dappos-button',
        `dappos-button--${props.size}`,
        `dappos-button--${props.type}`,
        `${props.loading ? 'dappos-button--loading' : ''}`,
        `${props.disabled ? 'dappos-button--disabled' : 'dappos-cursor-pointer'}`,
        attrs.class,
      ];
    });

    const handClick = (e: MouseEvent) => {
      if (props.loading) return;
      emit('click', e);
    };
    return () => (
      <button disabled={loading.value || disabled.value} class={className.value} onClick={(e) => handClick(e)}>
        {slots.default?.()}
      </button>
    );
  },
});
