import { defineComponent, computed, PropType, ref } from 'vue';
import { ItemData } from '~/core/wallet-lite';
export default defineComponent({
  name: 'WalletLiteContent',
  props: {
    item: {
      type: Object as PropType<ItemData>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: {
    click: (version: string) => true,
  },
  setup(_, { emit }) {
    const props = computed(() => {
      return _;
    });
    function handClick() {
      let href;
      if (props.value.item.blockExplorerUrl.endsWith('/')) {
        href = props.value.item.blockExplorerUrl + 'address/' + props.value.item.walletAddress;
      } else {
        href = props.value.item.blockExplorerUrl + '/address/' + props.value.item.walletAddress;
      }
      window.open(href, '_blank');
    }

    const addressFilter = (adr: string) => {
      if (!adr || adr.length < 10) return;
      adr = adr.toLocaleUpperCase();
      return `${adr.substring(0, 4)}...${adr.substring(adr.length - 4, adr.length)}`;
    };

    return () => (
      <button
        onClick={() => handClick()}
        class={{
          'wallet-lite-account-item': true,
        }}
      >
        <div class="wallet-lite-account-item__avatar">
          <img src={props.value.item?.iconUrl} alt="" />
        </div>
        <div class="wallet-lite-account-item__content">
          <div>
            <div class="wallet-lite-account-item__name wallet-lite-text-color-secondary">{props.value.item?.chainName}</div>
          </div>
          <div class="wallet-lite-account-item__tvl wallet-lite-text-color-secondary">
            <div>{addressFilter(props.value.item?.walletAddress)}</div>
          </div>
        </div>
      </button>
    );
  },
});
