import { signTxParam } from '@dappos/sdk-core';
import { ICallParam, VirtualWallet, getExecuteParamHash, getWorkFlowHash, ITxItem, ITxVW } from '@dappos/v2-sdk';
import { ethers } from 'ethers';
import { MerkleTree } from 'merkletreejs';
import { AddressZero, NODE_CALL_DATA } from '../constants';
import { AccountInfoConfig } from '../stores';

interface IPayMentParams {
  sender: string;
  owner: string;
  wallet: string;
  receiver?: string;
  state?: number;
  payment: {
    from: {
      chainId: number;
      amount: string;
      token: string;
    };
    to: {
      chainId: number;
      amount: string;
      token: string;
    };
    // k: string;
    // c: string;
    bridgeFee: string;
    vaultAddress: string;
    payOrderId: string;
  }[];
}

class PaymentsGenerator {
  private params: IPayMentParams;
  private _srcHash?: string | undefined;
  public get srcHash(): string | undefined {
    return this._srcHash;
  }
  public set srcHash(value: string | undefined) {
    this._srcHash = value;
  }
  private _dstHash?: string | undefined;
  public get dstHash(): string | undefined {
    return this._dstHash;
  }
  public set dstHash(value: string | undefined) {
    this._dstHash = value;
  }

  constructor(opt: IPayMentParams) {
    this.params = opt;
  }

  get state(): number {
    return this.params.state ?? 0;
  }
  set state(value: number) {
    this.params.state = value;
  }

  get orderParamStruct() {
    const { payment } = this.params;
    return payment.map((e) => {
      return {
        node: e.vaultAddress.length > 0 ? e.vaultAddress : AddressZero,
        payOrderId: e.payOrderId,
        amountIn: e.from.amount,
        amountOut: e.to.amount,
        tokenIn: e.from.token,
        tokenOut: e.from.token,
        bridgeFee: e.bridgeFee,
      };
    });
  }

  /**
   * generatePayment
   * @param {string} service
   * @param {string} data
   * @param {string} code
   */
  public generatePayment(code: string, service: string, data: string): ITxItem['createPays'] {
    const { owner, sender, receiver, wallet, payment } = this.params;
    const pay = payment.map((item, payIndex, arr) => {
      const { from, to, vaultAddress, payOrderId } = item;
      const walletAddress = wallet;
      const workflowHash = payIndex === arr.length - 1 ? getWorkFlowHash(walletAddress, code, service, data) : getWorkFlowHash(walletAddress, code);
      return {
        // vw
        code: code,
        workflowHash: workflowHash,
        owner: owner,
        state: this.state,

        // payment
        payOrderId,
        vaultAddress,

        // from
        srcChain: from.chainId,
        tokenIn: from.token,
        amountIn: from.amount,
        srcHash: this.srcHash,
        sender,

        // to
        dstChain: to.chainId,
        tokenOut: to.token,
        amountOut: to.amount,
        dstHash: this.dstHash,
        receiver: receiver ?? wallet,
      };
    });
    return pay;
  }
}

interface IVirtualWalletTransaction {
  virtualWallet: VirtualWallet;
  vw: {
    app: string;
    text: string;
    code: string;
    data?: string;
    service: string;
    isGateWay?: boolean;
  };
  gasLimit: string;
  gasTokenPrice: string;
  gasToken: string;
  priorityFee: string;
  createPays?: IPayMentParams['payment'];
  executePays?: IPayMentParams['payment'];
  to?: {
    chainId: number;
    address: string;
  };

  hash?: string;
  proof?: string;
  signature?: string;
  state?: number;
  nodeCallParam?: ICallParam;
}

class TransactionGenerate {
  private transactionRaw: IVirtualWalletTransaction;
  public executePaysGenerator?: PaymentsGenerator;
  public createPaysGenerator?: PaymentsGenerator;

  constructor(opt: IVirtualWalletTransaction) {
    this.transactionRaw = opt;
    this.transactionRaw.createPays &&
      (this.createPaysGenerator = new PaymentsGenerator({
        sender: this.wallet,
        wallet: this.wallet,
        owner: this.virtualWallet.owner,
        receiver: this.transactionRaw.to?.address,
        payment: this.transactionRaw.createPays,
      }));

    this.transactionRaw.executePays &&
      (this.executePaysGenerator = new PaymentsGenerator({
        sender: this.wallet,
        wallet: this.wallet,
        owner: this.virtualWallet.owner,
        receiver: this.transactionRaw.to?.address,
        payment: this.transactionRaw.executePays,
      }));
  }

  get virtualWallet() {
    return this.transactionRaw.virtualWallet;
  }

  get signature(): string | undefined {
    return this.transactionRaw.signature;
  }
  set signature(value: string | undefined) {
    this.transactionRaw.signature = value;
  }

  get proof(): string | undefined {
    return this.transactionRaw.proof ?? '';
  }
  set proof(value: string | undefined) {
    this.transactionRaw.proof = value;
  }

  get hash(): string | undefined {
    return this.transactionRaw.hash;
  }
  set hash(value: string | undefined) {
    this.transactionRaw.hash = value;
  }

  get data(): string {
    return this.transactionRaw.vw.data ?? '0x';
  }
  get service(): string {
    return this.transactionRaw.vw.service;
  }
  get dataHash(): string {
    return ethers.utils.keccak256(this.data);
  }

  get state(): number {
    return this.transactionRaw.state ?? 0;
  }
  set state(value: number) {
    this.transactionRaw.state = value;
    if (this.createPaysGenerator) this.createPaysGenerator.state = value;
    if (this.executePaysGenerator) this.executePaysGenerator.state = value;
  }

  get code() {
    return this.transactionRaw.vw.code;
  }

  get wallet() {
    return this.virtualWallet.address;
  }

  public generateWorkFlowHash() {
    const { code, data, service } = this.transactionRaw.vw;
    return getWorkFlowHash(this.wallet, code, service, data);
  }

  public generateData() {
    const { data } = this.transactionRaw.vw;

    if (this.createPaysGenerator) {
      const { code, service } = this.transactionRaw.vw;
      const { node, nodeCallData: callData } = this.transactionRaw.nodeCallParam ?? NODE_CALL_DATA;
      const cparam = {
        owner: this.virtualWallet.owner,
        wallet: this.wallet,
        receiver: this.transactionRaw.to?.address ?? this.wallet,
        action: 1,
      };

      const eparam = this.createPaysGenerator.orderParamStruct.map((e) => {
        return [e.amountIn, e.amountOut, e.payOrderId, e.bridgeFee, e.tokenIn, e.tokenOut, e.node];
      });

      const vw_data = ethers.utils.defaultAbiCoder.encode(
        ['tuple(address, address, address, uint256)', 'tuple(uint128, uint128, uint256, uint128, address, address, address)[]', 'tuple(uint256, address, bytes32)', 'tuple(address, bytes)'],
        [[cparam.owner, cparam.wallet, cparam.receiver, cparam.action], eparam, [code, service, this.dataHash], [node, callData]],
      );

      return vw_data;
    }
    return data ?? '0x';
  }

  get vw(): ITxVW {
    const vw: ITxVW = {
      // wallet
      chainId: this.virtualWallet.chainId,
      wallet: this.wallet,
      sender: this.virtualWallet.walletCreated ? this.wallet : this.virtualWallet.owner,
      owner: this.virtualWallet.owner,
      manager: this.virtualWallet.manager,

      // pay fee
      gasLimit: this.transactionRaw.gasLimit,
      gasToken: this.transactionRaw.gasToken,
      priorityFee: this.transactionRaw.priorityFee,
      gasTokenPrice: this.transactionRaw.gasTokenPrice,

      // protocol
      app: this.transactionRaw.vw.app,
      text: this.transactionRaw.vw.text,
      code: this.transactionRaw.vw.code,
      data: this.generateData(),
      service: this.transactionRaw.vw.service,
      isGateWay: this.transactionRaw.vw.isGateWay ? 1 : 0,
      workflowHash: this.generateWorkFlowHash(),
      signature: this.signature,
      proof: this.proof,
      hash: this.hash,
      state: this.state,
    };
    return vw;
  }

  public generateVw() {
    return this.vw;
  }

  public async generateSignature(opt: { signer: AccountInfoConfig['ownerSigner']; chainId: number; verifyingContract: string }) {
    const { signature } = await signTxParam({
      signer: opt.signer,
      chainId: opt.chainId,
      transactionParam: Object.assign(this.generateVw(), { verifyingContract: opt.verifyingContract, isGateway: Boolean(this.transactionRaw.vw.isGateWay) }),
      verifyingContract: opt.verifyingContract,
      version: this.virtualWallet.version,
    });
    const serviceSignature = signature;
    this.transactionRaw.signature = serviceSignature;
    return serviceSignature;
  }

  public generateTx() {
    const { code, service } = this.transactionRaw.vw;
    const vw = this.generateVw();
    return {
      vw: vw,
      createPays: this.createPaysGenerator?.generatePayment(code, service, vw.data) ?? [],
      executePays: this.executePaysGenerator?.generatePayment(code, service, vw.data) ?? [],
    };
  }

  public toJSON() {
    return this.generateTx();
  }

  public generateTransaction() {
    const vw = this.generateVw();
    return vw;
  }
}

class TxsGenerate {
  public txs: (TransactionGenerate | PaymentsGenerator)[];
  public virtualWallet: VirtualWallet;

  get transactionGenerator() {
    return this.txs.filter((e) => e instanceof TransactionGenerate) as TransactionGenerate[];
  }
  get paymentsGenerator() {
    return this.txs.filter((e) => e instanceof PaymentsGenerator) as PaymentsGenerator[];
  }
  constructor(opt: (TransactionGenerate | PaymentsGenerator)[], virtualwallet: VirtualWallet) {
    this.txs = opt;
    this.virtualWallet = virtualwallet;
  }

  static init(opt: IVirtualWalletTransaction[], virtualwallet: VirtualWallet) {
    const transactionGenerate = opt.map((e) => new TransactionGenerate(e));
    return new TxsGenerate(transactionGenerate, virtualwallet);
  }

  public async generateSignature(opt: { signer: ethers.providers.JsonRpcSigner; chainId: number; verifyingContract: string }) {
    const { signer, chainId, verifyingContract } = opt;
    const virtualWallet = this.virtualWallet;

    if (this.transactionGenerator.length === 1) {
      const tx = this.transactionGenerator[0];
      const vw = tx.generateVw();
      const signParam = Object.assign(vw, { isGateWay: Boolean(tx.vw.isGateWay), verifyingContract: opt.verifyingContract });
      const serviceSignature = await signer._signTypedData(...virtualWallet.getSignTypeDataParam(chainId, signParam));
      tx.signature = serviceSignature;
      return serviceSignature;
    } else {
      const txs = this.transactionGenerator;
      const transactionParamsAndHash = txs.map((e) => {
        const vw = e.generateVw();
        const paramHash = getExecuteParamHash(Object.assign(vw, { isGateWay: Boolean(vw.isGateWay) }));
        return {
          ...e,
          isGateWay: Boolean(e.vw.isGateWay),
          paramHash,
        };
      });
      const transactionParamMerkleTree = new MerkleTree(
        transactionParamsAndHash.map((e) => e.paramHash),
        ethers.utils.keccak256,
        { sortPairs: true, concatenator: Buffer.concat },
      );
      const rootHex = transactionParamMerkleTree.getHexRoot();
      const signature = await signer._signTypedData(
        ...virtualWallet.getSignRootHash(chainId, {
          rootHash: rootHex,
          verifyingContract: verifyingContract,
        }),
      );
      txs.forEach((e, i) => {
        e.proof = JSON.stringify(transactionParamMerkleTree.getHexProof(transactionParamsAndHash[i].paramHash));
        e.signature = signature;
      });
      return signature;
    }
  }

  generateOrderHash() {
    const tx = this.transactionGenerator[0];
    const txs = this.transactionGenerator;

    const _orderHash = ethers.utils.solidityKeccak256(
      ['address', 'string', 'string', 'string'],
      [this.virtualWallet.owner, tx.signature, tx?.code, txs.map((e) => e.createPaysGenerator?.orderParamStruct.map((e) => e.payOrderId)).join('-'), txs.map((e) => e.executePaysGenerator?.orderParamStruct.map((e) => e.payOrderId)).join('-')],
    );
    return _orderHash;
  }

  public generateTxs(code: string, service: string, data: string): ITxItem[] {
    const txs = this.txs.map((e, id): ITxItem => {
      if (e instanceof TransactionGenerate) {
        const tx = e.generateTx();
        return {
          id,
          createPays: tx.createPays,
          vw: tx.vw,
          executePays: [],
          dependenceIds: [],
        };
      }
      return {
        id,
        createPays: e.generatePayment(code, service, data),
        vw: undefined,
        executePays: [],
        dependenceIds: [],
      };
    });

    return txs;
  }
}

export { TxsGenerate, TransactionGenerate, PaymentsGenerator };
