import { PropType, computed, defineCustomElement, onErrorCaptured, provide, ref, watch } from 'vue';
import { useWalletLiteState } from '~/stores/wallet-lite.store';
import Popper from 'vue3-popper';
import { getAvatar, strFilter } from '~/utils';

import dContent from './components/d-content';
import css from './styles/wallet-lite.less?inline';
import { RuntimeContextKey } from '~/components/wallet-lite/common';
import { useWindowSize } from '@vueuse/core';

export type Connector = {
  name: string;
  logo: string;
  chaindId: number;
  showRed: boolean;
};

export default defineCustomElement({
  name: 'DappOSWalletLite',
  components: {
    dContent,
    Popper,
  },
  props: {
    wallet: {
      type: Boolean as PropType<boolean | string>,
      default: true,
    },

    size: {
      type: Number,
      default: 1,
    },
    owner: {
      type: String,
      default: undefined,
    },
    connector: {
      type: Object as () => Connector,
      required: true,
    },
    theme: {
      type: String,
      default: 'dark',
    },
    position: {
      type: String as PropType<
        | 'auto'
        | 'auto-start'
        | 'auto-end'
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'right'
        | 'right-start'
        | 'right-end'
        | 'left'
        | 'left-start'
        | 'left-end'
        | undefined
      >,
      default: 'bottom-start',
    },
  },
  styles: [css],
  emits: {
    versionChange: (version: string) => true,
    disconnect: () => true,
    deposit: () => true,
  },
  setup(_props, { emit }) {
    const walletListStore = useWalletLiteState();
    const { state, virtualWalletState, disconnect, open, connect, version } = walletListStore;
    console.log('update state value', _props.connector.name);
    state.value.connector = _props.connector.name;
    console.log('update state value success', state.value.connector);
    watch(
      () => _props.connector.name,
      (newVal, oldVal) => {
        state.value.connector = newVal;
      },
    );
    const { width } = useWindowSize();
    const popper_btn = ref();
    const popper = ref();
    const content = ref();

    const isOpen = open;

    const computedProps = computed(() => {
      return {
        ..._props,
        wallet: _props && ['false', '0'].includes(_props.wallet as any) ? false : Boolean(_props.wallet),
      };
    });
    provide(RuntimeContextKey, {
      props: computedProps,
      events: {
        versionChange: (version: string) => {
          emit('versionChange', version);
        },
        deposit: () => {
          emit('deposit');
        },
        disconnect: () => {
          popper.value.close();
          disconnect();
          emit('disconnect');
        },
      },
    });

    onErrorCaptured((err) => {
      console.error(err);
    });
    return () => (
      <>
        <Popper
          placement={computedProps.value.position}
          ref={(e) => (popper.value = e)}
          zIndex={9999999}
          onClose:popper={() => (isOpen.value = false)}
          onOpen:popper={() => (isOpen.value = true)}
        >
          {{
            default: () => (
              <>
                {state.value.owner && (
                  <button
                    class={[`wallet-lite-connect-btn size-${_props.size} ${virtualWalletState.isLoading.value ? 'loading' : ''}`]}
                    ref={(e) => {
                      popper_btn.value = e;
                      e && ((e as any).part = 'wallet-lite-connect-btn');
                    }}
                  >
                    <div class="wallet-lite-connect-btn__content">
                      {virtualWalletState.isLoading.value ? (
                        <>
                          <icon-eos-icons-loading class="wallet-lite-text-color-secondary" />
                        </>
                      ) : (
                        <>
                          <div class="avatar">
                            <>
                              <div>
                                <img src={getAvatar(state.value.owner!, version.value)} alt="dappOS" />
                              </div>
                            </>
                          </div>

                          <div style={'text-wrap: nowrap;'}>
                            <span class="wallet-lite-text-color-secondary">{strFilter(state.value.owner, width.value < 460 ? 2 : 4)}</span>
                          </div>

                          <div class="icon">
                            <icon-material-symbols-keyboard-arrow-down-rounded
                              class="dappos-cursor-pointer wallet-lite-text-color-secondary"
                              style={{
                                display: isOpen.value ? 'none' : 'block',
                              }}
                            />
                            <icon-material-symbols-keyboard-arrow-up
                              class="dappos-cursor-pointer wallet-lite-text-color-secondary"
                              style={{
                                display: !isOpen.value ? 'none' : 'block',
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {_props.connector.showRed && <div class="topred"></div>}
                  </button>
                )}
              </>
            ),
            content: () => (
              <div
                ref={(e) => {
                  content.value = e;
                  e && ((e as any).part = 'wallet-lite-popper ');
                }}
                class="wallet-lite-popper"
              >
                {!virtualWalletState.isLoading.value && <dContent connector={_props.connector} />}
              </div>
            ),
          }}
        </Popper>
      </>
    );
  },
});
