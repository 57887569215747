import { ConfigService, DappOSOrderData } from '@dappos/sdk-core';
import { DappOSPolyFactory, Solution } from '@dappos/v2-sdk';
import { TokenTransferServiceParam } from '@dappos/v2-sdk';
import { TokenTransferService } from '@dappos/v2-sdk';
import { ethers } from 'ethers';
import { ErrorReject } from '~/constants/error';
import { Vault_Info } from '~/contracts/vault-info';
import { DappOSCheckoutInstance } from '~/core';
import { Strategy } from '~/factory/strategics/strategy';
import { AccountStore, useCheckoutStore, useNodeInfoStore } from '~/stores';
import { ITransactionType, useRuntimeStore } from '~/stores/runtime.store';
import { getProviderAsync, logger } from '~/utils';
// import { TokenTransferService } from '../../../../v2-sdk/src';

export default class TokenTransferStrategy extends Strategy implements Strategy {
  constructor() {
    super({
      name: 'TokenTransfer Service',
      type: ITransactionType.tokenTransfer,
    });
  }

  async execute(param: TokenTransferServiceParam) {
    const runtimeStore = useRuntimeStore();
    const {
      runtimeState: { state },
      modalCtrl,
      promiseCtrl,
    } = runtimeStore;
    state.value.orderType = ITransactionType.tokenTransfer;
    state.value.params = param;
    modalCtrl.openModal();

    this._awaitConfirmPromise = new Promise<DappOSOrderData>((resolve, reject) => {
      promiseCtrl.resolve = resolve;
      promiseCtrl.reject = reject;
    }).finally(() => {
      runtimeStore.resetState();
      modalCtrl.closeModal();
    });
    // reject retry
    this._executePromise = this._execute(param).catch((e) => {
      console.error(e);
      throw e;
    });
    return this._awaitConfirmPromise;
  }
  private async _execute(param: TokenTransferServiceParam) {
    logger.info('execute', param);
    const { getServerNodeList } = useNodeInfoStore();
    const nodeList = await getServerNodeList();
    const accountStore = AccountStore();
    const { checkoutState } = useCheckoutStore();
    const aStore = {
      accountList: accountStore.accountList,
      accountChainId: accountStore.chainId,
      assets: accountStore.assets,
    };
    const polyFactory = new DappOSPolyFactory(checkoutState.state.value.networkListState, checkoutState.state.value.tokenWhiteList, aStore);

    const service = new TokenTransferService(polyFactory, nodeList);

    const result = await service.estimatedIntent(param, {
      chainId: accountStore.chainId!,
      connector: accountStore.connector.name,
      app: DappOSCheckoutInstance.getInstance().metadata?.name ?? '',
    });

    const runtimeStore = useRuntimeStore();
    const {
      runtimeState: { state },
    } = runtimeStore;

    state.value.solution = result.solution as Required<Solution>;
    state.value.fallbackSolutions = [];

    // verify solution
    await (async () => {
      const solution = state.value.solution;
      let isVerified = false;
      const node = solution?.serverNode;
      const vaultInfoAddress = ConfigService.getInstance().getConfig().dappOS_VaultInfo;
      if (node && vaultInfoAddress) {
        const provider = await getProviderAsync({ chainId: 56 });
        const vaultInfo = Vault_Info.init(vaultInfoAddress, provider);

        const params = <const>[node.id.toString(), solution.txs.map((e) => e.vw?.chainId).filter(Boolean) as number[]];
        const vaultInfos = await vaultInfo
          .getVaults(...params)
          .catch(() => vaultInfo.getVaults(...params))
          .catch((e) => {
            logger.error(e);
            return [];
          });
        isVerified = solution.txs
          .filter((e) => {
            const rules = [];
            if (solution.orderType.isolateExecute || solution.orderType.isolatePayment) {
              rules.push(!!e.vw);
            }

            rules.push(e.createPays?.length > 0 && e.createPays[0]?.vaultAddress !== ethers.constants.AddressZero);
            return rules.every(Boolean);
          })
          .every((e) => vaultInfos.find((v) => v.vaultAddress === e.createPays[0].vaultAddress?.toLowerCase()));

        solution.orderState.verify = isVerified;
        if (!isVerified && !solution.orderType.isolateExecute) {
          solution.orderState.failureReason = 'This node has not been verified.';
          solution.orderState.isDisabled = true;
        }
      }
    })();
  }

  public async estimatedMaxIntent(_param: TokenTransferServiceParam) {
    const { getServerNodeList } = useNodeInfoStore();
    const nodeList = await getServerNodeList();
    const accountStore = AccountStore();
    const { checkoutState } = useCheckoutStore();
    const aStore = {
      accountList: accountStore.accountList,
      accountChainId: accountStore.chainId,
      assets: accountStore.assets,
    };
    const polyFactory = new DappOSPolyFactory(checkoutState.state.value.networkListState, checkoutState.state.value.tokenWhiteList, aStore);
    const service = new TokenTransferService(polyFactory, nodeList);
    const result = await service.estimatedMaxIntent(_param);
    return result;
  }

  async confirm(): Promise<unknown> {
    const runtimeStore = useRuntimeStore();
    const { promiseCtrl } = runtimeStore;

    this._confirmPromise = this._confirm()
      .then((e) => {
        promiseCtrl.resolve(e);
        return e;
      })
      .catch((e) => {
        logger.error(e);
        promiseCtrl.reject({
          ...ErrorReject,
          body: e,
        });
      });
    return this._confirmPromise;
  }

  async _confirm() {
    const runtimeStore = useRuntimeStore();
    const {
      runtimeState: { state },
      promiseCtrl,
    } = runtimeStore;

    const accountStore = AccountStore();
    if (!accountStore.owner) {
      throw new Error('owner is null');
    }
    if (!accountStore.chainId || !accountStore.dappOSContracts) throw new Error(`unsupported chainId ${accountStore.chainId}`);

    const { solution } = state.value;

    const params = state.value.params as Required<TokenTransferServiceParam>;
    logger.info('execute', params, solution);
    const { getServerNodeList } = useNodeInfoStore();
    const nodeList = await getServerNodeList();
    const { checkoutState } = useCheckoutStore();
    const aStore = {
      accountList: accountStore.accountList,
      accountChainId: accountStore.chainId,
      assets: accountStore.assets,
    };
    const polyFactory = new DappOSPolyFactory(checkoutState.state.value.networkListState, checkoutState.state.value.tokenWhiteList, aStore);

    const service = new TokenTransferService(polyFactory, nodeList);
    const signer = accountStore.getSigner();
    const checkoutInstance = DappOSCheckoutInstance.getInstance();
    const eoaChainId = accountStore.chainId!;
    try {
      solution.txs = TokenTransferService.generateTxs(
        {
          solution: solution,
          eoaChainId,
          app: checkoutInstance.metadata?.name ?? '',
          connector: accountStore.connector.name,
        },
        params,
      );
    } catch (error) {
      console.error(error);
      throw new Error(`generate txs error ${error}`);
    }

    const result = await service
      .executeSolution(solution, params, {
        signer: signer,
        chainId: accountStore.chainId,
        provider: accountStore.provider!,
      })
      .catch((e) => {
        logger.error(e);
        promiseCtrl.reject({
          ...ErrorReject,
          body: e,
        });
        throw e;
      });

    return result;
  }

  async estimatedIntent(param: TokenTransferServiceParam) {
    const { getServerNodeList } = useNodeInfoStore();
    const nodeList = await getServerNodeList();
    const accountStore = AccountStore();
    const { checkoutState } = useCheckoutStore();
    const aStore = {
      accountList: accountStore.accountList,
      accountChainId: accountStore.chainId,
      assets: accountStore.assets,
    };
    const polyFactory = new DappOSPolyFactory(checkoutState.state.value.networkListState, checkoutState.state.value.tokenWhiteList, aStore);

    const service = new TokenTransferService(polyFactory, nodeList);

    const result = await service.estimatedIntent(param, {
      chainId: accountStore.chainId!,
      connector: accountStore.connector.name,
      app: DappOSCheckoutInstance.getInstance().metadata?.name ?? '',
    });
    return result;
  }
}
