import BigNumber from 'bignumber.js';

export interface BN extends BigNumber {
  toBigInt: (this: BigNumber) => bigint;
  toHex: (this: BigNumber) => `0x${string}`;
}

export const BN = BigNumber.clone({ EXPONENTIAL_AT: [-30, 30], ROUNDING_MODE: BigNumber.ROUND_DOWN });
export type BigNumberValue = BigNumber.Value;

const applyFunction: Pick<BN, 'toBigInt' | 'toHex'> = {
  toBigInt() {
    return BigInt(this.toString());
  },
  toHex() {
    const hex = this.dp(0).toString(16);
    if (hex === '0') return '0x';
    return `0x${hex}`;
  },
};

Object.assign(BN.prototype, applyFunction);

export const bnUtils = {
  wrap: (tar: BigNumberValue | bigint) => {
    return new BN(typeof tar === 'bigint' ? tar.toString() : tar);
  },
  toWei: (tar: BigNumberValue, decimals: BigNumberValue) => {
    return bnUtils.wrap(tar).times(new BN(10).pow(bnUtils.wrap(decimals)));
  },
  fromWei: (tar: BigNumberValue, decimals: BigNumberValue) => {
    return bnUtils.wrap(tar).div(new BN(10).pow(bnUtils.wrap(decimals)));
  },
};
