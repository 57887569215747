import { VirtualWallet, IServerNode } from '@dappos/v2-sdk';
import { TransactionParam } from '@dappos/sdk-core';
import { signTransaction as sdkSignTransaction } from '@dappos/sdk-core';
import { BigNumberValue } from '../utils/bn';

import { ethers } from 'ethers';
import multicall from 'ethers-multicall';
import { Raw, ref } from 'vue';
import { AddressZero, erc20Abi_constants } from '../constants';
import BigNumber from 'bignumber.js';
import { getProviderAsync } from '../utils/provider';
import { useNetworkStore } from '../stores/checkout.store';
import { AccountStore } from '../stores/account.store';
import { BaseContract } from '../contracts/base-contract';

export const strFilter = (adr?: string) => {
  if (!adr || adr.length < 10) return;
  adr = adr.toLocaleUpperCase();
  return `${adr.substring(0, 6)}...${adr.substring(adr.length - 4, adr.length)}`;
};

export function limitDecimals(amount: BigNumber | string | number, maxDecimals: number) {
  let amountStr = new BigNumber(amount).toFixed(18, 1);
  if (maxDecimals === undefined) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    if (amountStr.indexOf('.') === -1) {
      return amountStr;
    }
    return amountStr.split('.')[0] as string;
  }
  const dotIndex = amountStr.indexOf('.');
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substring(0, dotIndex + maxDecimals + 1);
    }
  }
  return amountStr;
}

export async function getVerifyingContract(chainId: number) {
  const networkStore = useNetworkStore();
  const accountStore = AccountStore();
  const verifyingContract = networkStore.getEvmChainsParameter(chainId, accountStore.version)?.contract?.manager;
  if (!verifyingContract) {
    throw new Error('verifyingContract is not found');
  }
  return verifyingContract;
}

export async function signTransaction(transactionParams: TransactionParam[], virtualWallet: VirtualWallet | Raw<VirtualWallet>) {
  const accountStore = AccountStore();

  const signer = accountStore.getSigner();
  const chainId = accountStore.chainId;

  if (!chainId || !signer) {
    throw new Error('signer is not found');
  }
  const verifyingContract = await getVerifyingContract(chainId);

  return await sdkSignTransaction({
    signer: signer,
    chainId: chainId,
    transactionParams: transactionParams,
    verifyingContract: verifyingContract,
    virtualWallet: virtualWallet,
  });
}

export async function getAssetsByMulticall(opt: { chainId: number; provider: ethers.providers.Provider; owner: string; tokens: string[] }) {
  const { owner, provider, tokens, chainId } = opt;
  const ethcallProvider = new multicall.Provider(provider, chainId);
  const result = await ethcallProvider.all(
    tokens.map((item) => {
      if (item === AddressZero) {
        return ethcallProvider.getEthBalance(owner);
      }
      const erc20Contract = new multicall.Contract(item, [
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'balanceOf',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ]);
      return erc20Contract.balanceOf(owner);
    }),
  );
  return tokens.map((item, index) => {
    return { address: item, balance: result[index].toString() as string };
  });
}

export async function getVirtualWalletAssets(
  virtualwallet: VirtualWallet,
  tokens: string[],
): Promise<
  {
    address: string;
    balance: string;
  }[]
> {
  return await getAssetsByMulticall({
    chainId: virtualwallet.chainId,
    provider: virtualwallet.provider,
    owner: virtualwallet.address,
    tokens,
  });
}

export async function getTokensAllowance(opt: { owner: string; spender: string; tokens: string[]; provider: ethers.providers.Provider; chainId: number }): Promise<
  {
    address: string;
    allowance: string;
    owner: string;
    spender: string;
  }[]
> {
  const { owner, spender, tokens, provider, chainId } = opt;
  const ethcallProvider = new multicall.Provider(provider, chainId);
  const result = await ethcallProvider.all(
    tokens.map((item) => {
      return new multicall.Contract(item, [erc20Abi_constants.allowance]).allowance(owner, spender);
    }),
  );
  return tokens.map((item, index) => {
    return { address: item, allowance: result[index].toString() as string, owner, spender };
  });
}

export const useToggle = (isOpen = false) => {
  const _isOpen = ref(isOpen);

  const open = () => (_isOpen.value = true);
  const close = () => (_isOpen.value = false);
  const toggle = () => (_isOpen.value = !_isOpen.value);
  return {
    isOpen: _isOpen,
    open,
    close,
    toggle,
  };
};

export async function autoApproveErc20Token(tokens: { address: string; amount: string }[], spender: string, deep?: boolean): Promise<boolean> {
  const accountStore = AccountStore();
  const provider = await getProviderAsync({ chainId: accountStore.chainId! });
  const signer = accountStore?.getSigner();
  const owner = accountStore.owner!;

  const tokenAllowanceList = await getTokensAllowance({
    chainId: accountStore.chainId!,
    provider: provider,
    tokens: tokens.map((e) => e.address),
    owner: owner,
    spender: spender,
  });

  const allowanceEnoughFilter = tokenAllowanceList
    .map((e, i) => {
      return {
        ...e,
        amount: tokens[i].amount,
      };
    })
    .filter((e, i) => {
      return new BigNumber(e.amount).gt(e.allowance);
    });

  if (allowanceEnoughFilter.length === 0) return true;

  const result = await Promise.all(
    allowanceEnoughFilter.map(async (e) => {
      const tokenContract = new BaseContract([erc20Abi_constants.approve], e.address, provider, signer);
      const params = [spender, new BigNumber(e.amount).multipliedBy(2).toFixed(0)];
      const tx = await tokenContract.write('approve', params);
      await tx.wait(1);
    }),
  )
    .then(() => true)
    .catch(() => false);

  if (deep) {
    return await autoApproveErc20Token(tokens, spender, true);
  } else {
    return result;
  }
}

export function NumberFixed(n: BigNumber | string | number, fixed = 18) {
  const _n = new BigNumber(n);

  const dp = _n.dp();
  if (dp && dp >= fixed) {
    return _n.toFixed(fixed, 1);
  }

  if (!_n.isFinite()) {
    return '0';
  }
  return _n.toFixed();
}

/**
 * isEqualsToken
 * @param adrs string[]
 */
export function isEqualAddress(...adrs: (string | undefined)[]) {
  const indexAdr = adrs[0]?.toLowerCase();
  return adrs.every((e, i) => e?.toLowerCase() === indexAdr);
}

export function concatData(data: (string | undefined)[]) {
  return `0x${data.map((e) => (e ?? '0x').replace('0x', '')).join('')}`;
}

export function isLargeNode(node?: IServerNode) {
  return node && ([6, 4, 14].includes(node.id) || node.type === 3);
}

export function generateOrderHash(args: (string | undefined)[]): string {
  const salt = `${Math.random()}-${new Date().getTime()}`;
  const _args = args.filter(Boolean) as string[];
  const value = [..._args, salt];
  const types = value.filter(Boolean).map((e) => (ethers.utils.isAddress(e) ? 'address' : 'string'));

  return ethers.utils.solidityKeccak256(types, value);
}

const gas_config = [
  {
    chainId: 169,
    scalar: 1.333333,
    decimals: 1e6,
    overhead: 1000,
  },
  {
    chainId: 10,
    scalar: 0.684,
    decimals: 1e6,
    overhead: 188,
  },
];

/**
 * estimatedL1Gas
 * @description l1BaseFee * (l1_gas_used + overhead) * scalar / decimals
 * @link https://hackmd.io/@rajivpoc/SJzAVsB15#:~:text=The%20L1%20data,of%20the%20predeploy%3A
 * @param _data estimated data
 * @param param
 * @returns estimated gas
 */
export function estimatedL1Gas(_data: string, param: { virtualWallet: VirtualWallet }) {
  const { virtualWallet } = param;
  const rawData = _data
    .split('0x')[1]
    .split('')
    .map((_, i, arr) => (i % 2 ? null : `${arr[i]}${arr[i + 1]}`))
    .filter(Boolean);
  const zero_data = rawData.filter((e) => e === '00');
  const non_zero_data = rawData.filter((e) => e !== '00');
  const total_zero_data = zero_data.length * 4;
  const total_non_zero_data = non_zero_data.length * 16;
  const total = total_zero_data + total_non_zero_data;
  const config = gas_config.find((e) => e.chainId === virtualWallet.chainId) ?? { scalar: 1, decimals: 1, overhead: 0 };
  const unsigned = total + config.overhead;
  return unsigned + 68 * 16; // * config.scalar; // / config.decimals;
}
export function formatDigit(p: BigNumberValue | undefined) {
  const _r = Number(p);
  const price = Number.isNaN(p) ? 0 : _r;
  if (price < 10) {
    return 2;
  } else if (price < 100) {
    return 3;
  } else if (price < 1000) {
    return 4;
  } else if (price < 10000) {
    return 5;
  } else if (price > 10000) {
    return 6;
  }
  return 4;
}
