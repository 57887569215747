import { defineComponent } from 'vue';
import { useRuntimeStore } from '../../../stores';
import { Button as DButton } from '../../../ui/components';

export default defineComponent({
  name: 'Revoke',
  components: {
    DButton,
  },
  setup() {
    const { modalCtrl } = useRuntimeStore();

    return () => (
      <>
        <div class='dappos-container dappos-revoke'>
          <div class='dappos-checkout-header dappos-text-center'>
            <div> </div>
            <div class='dappos-header-title'>
              <span> dappOS V1 Migration </span>
            </div>
            <div> </div>
          </div>

          <div class='dappos-checkout-content'>
            <div class='dappos-flex dappos-items-center dappos-justify-center dappos-mb-16'>
              <span class='dappos-text-center'> dappOS V1 has phased out on 8th Dec (00:00 UTC). Please complete the migration as soon as possible. </span>
            </div>
            <div class='dappos-confirm-footer dappos-w-full dappos-flex dappos-justify-center dappos-items-center' style={{ 'justify-content': 'center' }}>
              <a href='https://v1bonus.dappos.com/home' rel='noopener' class='dappos-block dappos-w-full' style={{ 'max-width': '244px' }}>
                <DButton size='sm' type='primary' class='dappos-w-full' style='width: 100%' onClick={() => modalCtrl.switchRevoke(false)}>
                  <span>Confirm</span>
                </DButton>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  },
});
