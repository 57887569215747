import { computed, reactive } from 'vue';
import { useAsyncState, useStorage, useToggle } from '@vueuse/core';
import { DappOSApi, AccountInfo, VirtualWallet, IVirtualWallet } from '@dappos/sdk-core';
import { useCookies } from '@vueuse/integrations/useCookies';
import Cookies from 'universal-cookie';
import axios from 'axios';

export const appConfigs: Record<string, string[]> = {
  avascription: ['2.1'],
};

type ChainConfig = {
  chainName: string;
  blockExplorerUrl: string;
  iconUrl: string;
};

type ChainIdWithBlockUrl = {
  [key: number]: ChainConfig;
};

interface MetaData {
  app: string;
}
interface WalletLiteState {
  open: boolean;
  owner?: string;
  includeChainId?: string;
  version?: string;
  metadata?: MetaData;
  config: ChainIdWithBlockUrl;
  supportDstChains: number[];
  chainsToCreateVw: number[];
  connector: string;
  vwInfos: IVirtualWallet[];
  createVwRequestLoading: boolean;
}

const [open, toggle] = useToggle(false);

type VersionMap = Record<string, string>;
const cookieKey = 'dappos:wallet-lite:version';
const domain = (() => {
  try {
    if (['localhost', '127.0.0.1'].includes(window.location.hostname) || window.location.hostname.includes('192.168')) {
      return window.location.hostname;
    }
    return window.location.hostname.split('.').slice(-2).join('.');
  } catch (error) {
    return undefined;
  }
})();

const versionMapCookie = useCookies(
  [cookieKey],
  {
    doNotParse: false,
    autoUpdateDependencies: true,
  },
  new Cookies(null, { domain: domain, maxAge: 60 * 60 * 24 * 365 }),
);

// max size 4kb
const versionMapStorage = useStorage<VersionMap>(cookieKey, <VersionMap>{}, {
  getItem: (k: string) => {
    return versionMapCookie.get(k);
  },
  setItem: (k, v) => {
    versionMapCookie.set(k, v);
  },
  removeItem: (k) => {
    versionMapCookie.remove(k);
  },
});

const state = reactive<WalletLiteState>({
  open: open.value,
  owner: undefined,
  includeChainId: undefined,
  version: undefined,
  metadata: undefined,
  config: {},
  supportDstChains: [],
  chainsToCreateVw: [],
  connector: '',
  vwInfos: [],
  createVwRequestLoading: false,
});

const virtualWalletState = useAsyncState(
  async (owner: string) => {
    if (owner) {
      const api = DappOSApi.getInstance();
      const result = (await api.getVirtualWallets(owner).catch(() => {
        return [];
      })) as AccountInfo;
      if (result && result.infos && result.infos.length != 0) {
        state.vwInfos = result.infos;
        return [result];
      }
      //can't get vw from api , so get vw from blockchain if it was oid and support
      // const hasSupportArb = state.supportDstChains.includes(state.whichChainCreateVw);
      console.log('state connector', state.connector);
      const isCubist = state.connector == 'twitter' || state.connector === 'google' || state.connector === 'facebook';

      if (isCubist) {
        const vwInfos = [
          {
            version: '2.0',
            infos: [] as any,
            total: '0',
            ownerAddress: owner,
            id: -1,
            insertTime: '',
            isContract: 0,
            inviteCode: '',
            referralAddress: '',
            vipLever: 0,
            lastUpdateTime: null,
          },
        ];
        for (let index = 0; index < state.chainsToCreateVw.length; index++) {
          const chainId = state.chainsToCreateVw[index];
          const virtualWallet = await VirtualWallet.init(owner, {
            version: '2',
            chainId: chainId,
          });
          const vw = virtualWallet.address;
          if (vw !== '0x0000000000000000000000000000000000000000') {
            const chainInfo: any = {
              walletAddress: vw,
              chainId: chainId,
            };
            vwInfos[0].infos.push(chainInfo);
          }
        }
        if (vwInfos[0].infos && vwInfos[0].infos.length != 0) {
          state.vwInfos = vwInfos[0].infos;
          console.log('get vws from chain ', state.vwInfos);
          return vwInfos;
        }
        console.log('get vws from chain ', []);

        return [];
      }
      return [];
    } else {
      return [];
    }
  },
  [],
  {
    resetOnExecute: false,
  },
);

const networkState = useAsyncState<ChainConfig[]>(
  async () => {
    const result = await DappOSApi.getInstance().getChainConfigByCDN();
    return result;
  },
  [],
  { immediate: true },
);
const syncStorage = () => {
  versionMapStorage.value = versionMapCookie.get(cookieKey) ?? {};
};

const useWalletLiteState = () => {
  const version = computed(() => {
    if (state.owner?.toLowerCase()) {
      const version = versionMapStorage.value[state.owner?.toLowerCase() ?? ''];
      return version ?? state.version;
    }
    return state.version;
  });

  const connect = async (params: { owner: string; supportDstChains: number[]; open?: string; chainsToCreateVw: number[] }) => {
    state.vwInfos = [];
    virtualWalletState.state.value = [];
    syncStorage();
    state.supportDstChains = params.supportDstChains;
    state.chainsToCreateVw = params.chainsToCreateVw;
    await (async () => {
      if (state.owner && state.owner.toLowerCase() === params.owner.toLowerCase() && virtualWalletState.state.value.length > 0) {
        virtualWalletState.execute(0, params.owner);
        return virtualWalletState.state.value;
      } else {
        state.owner = params.owner;
        return await virtualWalletState.execute(0, params.owner);
      }
    })();

    requestVw(params.owner);

    const configs = await axios.get('https://dappos-public-resource.s3.amazonaws.com/configFile/dappos-config-chains-v2_1.json');
    if (configs.status === 200) {
      const data = configs.data;
      if (data) {
        data.map((item: any) => {
          const chainId = item.chainId;
          const blockExplorerUrl = item.blockExplorerUrl;
          state.config[chainId] = {
            chainName: item.chainName,
            blockExplorerUrl: item.blockExplorerUrl,
            iconUrl: item.iconUrl,
          };
          return {
            chainId: chainId,
            blockExplorerUrl: blockExplorerUrl,
          };
        });
      }
    }
    setVersion('');
    return {
      version: setVersion(''),
    };
  };

  const disconnect = async () => {
    toggle(false);
    state.owner = undefined;
    state.vwInfos = [];
    virtualWalletState.state.value = [];
  };

  const setVersion = (version: string) => {
    versionMapStorage.value = {
      ...versionMapStorage.value,
      [state.owner?.toLowerCase() ?? '']: version,
    };
  };

  return {
    state: computed(() => state),
    setVersion,
    version,
    open,
    virtualWalletState,
    networkState,
    connect,
    disconnect,
    toggle,
  };
};

export const requestVw = (owner: string) => {
  console.log('requestVw', state.connector);

  const isCubist = state.connector === 'twitter' || state.connector === 'google' || state.connector === 'facebook';
  if (!isCubist) {
    return;
  }

  let chainToFetch = [];
  if (state.vwInfos.length === 0) {
    chainToFetch = state.chainsToCreateVw;
  } else {
    const infosChainId = state.vwInfos.map((item: any) => item.chainId);
    chainToFetch = state.chainsToCreateVw.filter((chainIdToCreateVw) => {
      return !infosChainId.includes(chainIdToCreateVw);
    });
  }
  console.log(chainToFetch);

  for (let index = 0; index < chainToFetch.length; index++) {
    const element = chainToFetch[index];
    state.createVwRequestLoading = true;
    const keyId = {
      owner: owner,
      chainId: element,
    };
    fetch('https://cubist.dappos.com/createVirtualWalletByOwner', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(keyId),
    })
      .then(async (v) => {
        const hasSupportArb = state.supportDstChains.includes(element);
        if (!hasSupportArb) {
          return;
        }
        console.log(`create vw on ${element}`, v);
        if (owner !== state.owner) {
          console.log(`this request is belong to ${owner},but current is ${state.owner}`);
          return;
        }
        if (v.status === 200 || v.status === 201) {
          const data = await v.json();
          const code = data['code'];
          if (code === 200) {
            const address = data['data'];
            const vw = {
              walletAddress: address,
              chainId: element,
            };
            state.vwInfos.push(vw as any);
            state.createVwRequestLoading = false;
          } else {
            state.createVwRequestLoading = false;
          }
        } else {
          state.createVwRequestLoading = false;
        }
      })
      .catch((e) => {
        state.createVwRequestLoading = false;
      });
  }
};

export { useWalletLiteState };
export type { MetaData };
