import { ITransactionType } from '../../stores/runtime.store';
import { DappOSOrderData } from '@dappos/sdk-core';
import { ServiceParamAbstract } from '@dappos/v2-sdk';

interface TransactionTypeRecord {
  name: string;
  type: ITransactionType;
}
export abstract class Strategy {
  public readonly transactionType: TransactionTypeRecord;
  constructor(transactionType: TransactionTypeRecord) {
    this.transactionType = transactionType;
  }

  // getRuntimeStore() {
  //   return useRuntimeStore();
  // }

  promiseCtrl = {
    resolve: {} as (value: DappOSOrderData) => void,
    reject: {} as (reason?: any) => void,
  };

  _executePromise: Promise<unknown> | null = null;
  abstract execute(param: ServiceParamAbstract): Promise<DappOSOrderData>;
  // abstract retryExecute?(): Promise<IDappOSOrderData> | null;

  _awaitConfirmPromise: Promise<DappOSOrderData> | null = null;
  _confirmPromise: Promise<unknown> | null = null;
  abstract confirm(): Promise<unknown>;
  // abstract simulate(metadata: ITransactionParam): Promise<unknown>;
}
